import { Badge, Typography } from "antd";
import EmployeeMngmentCard from "../../reuseables/EmployeeMngmentCard";
import { useEffect, useState } from "react";
import {
  useEmployeeDepartmentQuery,
  useLazyFetchEmployeeQuery,
} from "../../../store";

interface CardInfo {
  id: number;
  position: string;
  name: string;
  department: string;
  deptId: number;
}

type ManagementProps = {
  active: number;
};
const Management = ({ active }: ManagementProps) => {
  const { Text, Paragraph } = Typography;

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [
    trigger,
    { data: fetchEmployees, isLoading, isSuccess, isFetching, isError },
  ] = useLazyFetchEmployeeQuery();

  useEffect(() => {
    trigger({ page, pageSize, management: true });
    console.log("retrigger");
    console.log(isLoading);
    if (isLoading) {
      console.log("loading...");
    } else {
      // console.log(fetchEmployees);
      console.log("done loading");
    }
  }, [active, page, pageSize, isLoading, isSuccess]);

  let content: React.ReactNode = <></>;
  if (isLoading) {
    content = <div>Loading...</div>;
  } else {
    const data = fetchEmployees?.data?.map((item: any, index: number) => ({
      id: item.id,
      position: item.job_title?.name,
      name: `${item.user.first_name} ${item.user.last_name}`,
      department: item.department?.name,
      deptId: item.department?.id,
    }));
    content = (
      <>
        <div className="grid grid-flow-row grid-cols-3 gap-10 justify-center items-center px-36">
          {data
            ?.filter((_: any, index: number) => index < 3)
            .map(({ id, position, name, department, deptId }: CardInfo) => (
              <Badge.Ribbon text="Hello" key={id}>
                <EmployeeMngmentCard>
                  <EmployeeMngmentCard.Profile source="../images/jimmy.png" />
                  <div className=" flex flex-col justify-center items-center">
                    <EmployeeMngmentCard.Name name={name} id={id} />
                    <EmployeeMngmentCard.Position position={position} />
                    <EmployeeMngmentCard.Department department={department} />
                  </div>
                  {EmployeeMngmentCard.Social && (
                    <EmployeeMngmentCard.Social viber="#" email="#" />
                  )}
                </EmployeeMngmentCard>
              </Badge.Ribbon>
            ))}
        </div>
        <div className="grid grid-flow-row grid-cols-4 gap-10 my-10">
          {data
            ?.filter((_: any, index: number) => index > 2)
            .map(
              (
                { id, position, name, department, deptId }: CardInfo,
                index: number
              ) => (
                <EmployeeMngmentCard key={index}>
                  <EmployeeMngmentCard.Profile source="../images/jimmy.png" />
                  <div className=" flex flex-col justify-center items-center">
                    <EmployeeMngmentCard.Name name={name} id={id} />
                    <EmployeeMngmentCard.Position position={position} />
                    <EmployeeMngmentCard.Department department={department} />
                  </div>
                  {EmployeeMngmentCard.Social && (
                    <EmployeeMngmentCard.Social viber="#" email="#" />
                  )}
                  {EmployeeMngmentCard.Modal && (
                    <EmployeeMngmentCard.Modal
                      id={id}
                      department={department}
                      deptId={deptId}
                    />
                  )}
                </EmployeeMngmentCard>
              )
            )}
        </div>
      </>
    );
  }
  return (
    <>
      <div className=" flex flex-col items-center justify-center mt-16 mb-12 space-y-3">
        <Text className="font-sans text-3xl font-bold">Management Team</Text>
        <Paragraph className=" font-sans text-lg text-neutral-500">
          Hi, we are the management team! Here to drive innovation, growth, and
          success every step of the way.
        </Paragraph>
      </div>
      {content}
    </>
  );
};

export default Management;
