import React, { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { Input, Pagination, PaginationProps, Spin, Alert } from "antd";
import CustomContainer from "../../reuseables/CustomContainer";
import { ImParagraphLeft } from "react-icons/im";
import { FaBorderAll } from "react-icons/fa6";
import { useMemoListPageQuery } from "../../../store";
import CardMemos from "./CardMemos";
import TableMemo from "./TableMemo";
import AddMemo from "../../modals/WhatsNew/AddMemo";

const MemoTabs = () => {
  const [pageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [dataStyle, setDataStyle] = useState(1);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setPage(1);
  };

  const onChangePage: PaginationProps["onChange"] = (page) => {
    setPage(page);
  };

  const updateDataStyle = (val: number) => {
    setDataStyle(val);
  };

  // API Call
  const memoListApi = useMemoListPageQuery({
    page,
    pageSize,
    search
  });


  return (
    <CustomContainer>
      <div className="mt-10 space-y-5">
        <div className="flex items-center justify-between">
          <div className="flex gap-2">
            <Input
              placeholder="Search.."
              defaultValue={search}
            //   onKeyUp={handleSearchChange}
              className="custom-style-input w-72"
              suffix={<BsSearch />}
            />
            {dataStyle === 1 ? (
              <button onClick={() => updateDataStyle(2)}>
                <ImParagraphLeft />
              </button>
            ) : (
              <button onClick={() => updateDataStyle(1)}>
                <FaBorderAll />
              </button>
            )}
          </div>
          <AddMemo />
        </div>

        {/* Loading State */}
        {memoListApi.isLoading && (
          <div className="flex justify-center items-center h-40">
            <Spin tip="Loading memos..." />
          </div>
        )}

        {/* Error State */}
        {memoListApi.isError && (
          <Alert
            message="Error"
            description="Failed to load memos. Please try again later."
            type="error"
            showIcon
          />
        )}

        {/* Success State */}
        {memoListApi.isSuccess && memoListApi.data?.results?.length > 0 && (
          dataStyle === 1 ? (
            <CardMemos data={memoListApi.data.results} />
          ) : (
            <TableMemo data={memoListApi.data.results} />
          )
        )}

        {/* Empty State */}
        {memoListApi.isSuccess && memoListApi.data?.results?.length === 0 && (
          <div className="text-center text-gray-500">No memos found.</div>
        )}

        <Pagination
          className="flex justify-end"
          current={page}
          onChange={onChangePage}
          total={memoListApi.data?.count || 0}
          defaultPageSize={pageSize}
        />
      </div>
    </CustomContainer>
  );
};

export default MemoTabs;