import { Input, Select, Space, Typography } from "antd";
import React from "react";
import { useMultiStep } from "../../context/StepContext";

const AddNewEmployeeCompensation = () => {
  const { empInputs, setEmpInputs } = useMultiStep();
  const { Text } = Typography;

  interface InputTypes {
    id: number;
    name: string;
    amount: string;
    inputName: string;
    selectName: string;
  }

  type Inputs = {
    transpo: number | string;
    rent: number | string;
    telco: number | string;
    transpoTerm: string;
    rentTerm: string;
    telcoTerm: string;
  };
  const benefits: InputTypes[] = [
    {
      id: 2,
      name: "Transportation Allowance",
      inputName: "transpo",
      selectName: "transpoTerm",
      amount: "",
    },
    {
      id: 3,
      name: "Rent Allowance",
      inputName: "rent",
      selectName: "rentTerm",
      amount: "",
    },
    {
      id: 4,
      name: "Telecommunication",
      inputName: "telco",
      selectName: "telcoTerm",
      amount: "",
    },
  ];

  const handleChangeInput = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEmpInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (
    val: number | null,
    label: string | number,
    name: string
  ) => {
    setEmpInputs((prev) => ({
      ...prev,
      [name]: label,
    }));
  };

  return (
    <>
      <Space className="block mt-10 mb-8 space-y-5 mx-16">
        <div className=" pb-3">
          <Text className=" font-sans text-2xl font-bold text-[#0056D2]">
            Benefits
          </Text>
        </div>
        <div className=" flex flex-col">
          <Text className="font-sans">HMO</Text>
          <div className=" flex items-center space-x-10">
            <Input
              name="hmo"
              placeholder="Health Card Number"
              className="custom-style-input mt-2 w-full"
              value={empInputs.hmo ?? ""}
              onChange={handleChangeInput}
            />
            <Input
              name="hmoTerm"
              value={empInputs.hmoTerm ?? ""}
              placeholder="Maximum Benefit Limit"
              className="custom-style-input mt-2 w-full"
              onChange={handleChangeInput}
            />
          </div>
        </div>
        {benefits.map((entry) => (
          <div className=" flex flex-col" key={entry.id}>
            <Text className="font-sans">{entry.name}</Text>
            <Space className="grid grid-cols-2 grid-flow-row gap-10">
              <Input
                name={entry.inputName}
                placeholder="Amount"
                className="custom-style-input mt-2 w-full"
                value={empInputs[entry.inputName as keyof Inputs] ?? ""}
                onChange={handleChangeInput}
              />
              <Select
                placeholder="Availability"
                value={empInputs[entry.selectName as keyof Inputs]}
                className="font-sans text-sm mt-2 w-full"
                options={[
                  { value: 1, label: "Month" },
                  { value: 2, label: "Year" },
                ]}
                onChange={(value, option) => {
                  let label = "";
                  if (Array.isArray(option)) {
                    label = String(option[0]?.label || "");
                  } else {
                    label = String(option?.label || "");
                  }
                  handleSelectChange(
                    Number(value),
                    value,
                    `${entry.selectName}`
                  );
                }}
              />
            </Space>
          </div>
        ))}
      </Space>
    </>
  );
};

export default AddNewEmployeeCompensation;
