import React from 'react';
import { Button, Checkbox, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MemoModal from '../../modals/WhatsNew/MemoModal';
import {Memo} from '../../../utils/type';

interface Props {
  data: Memo[];
}

const TableMemo = ({data}: Props) => {
  console.log(data);
  return (
    <div className="bg-gray-50 p-8">
        <div className="flex">
            <img src="../images/whatsNew/whatNewImagetableHeader.png" alt="What's New" className="mr-2 h-18 w-20" />
            <Typography variant="h4" className="text-yellow-600 font-semibold mb-4">
                Memos
            </Typography>
        </div>
        <Typography variant="body1" className="text-gray-700 mb-8">
            Stay updated with the latest features, improvements, and announcements! Explore our 'What's New' page to discover fresh content, product updates, and exciting developments happening in our community.
        </Typography>
        <div className="border-t border-b border-yellow-600 p-4">
        {data.map((memo: Memo, index:number) => (
          <div key={index} className="flex items-center justify-between py-4 border-b">
            <div className="flex items-center">
              <Checkbox />
              <div className="ml-4">
                <MemoModal title={memo.title} id={memo.id}/>
                <Typography variant="body2" className="text-gray-500">{memo.date_uploaded} | {memo.uploaded_by.first_name} {memo.uploaded_by.last_name}</Typography>
              </div>
            </div>
            <Button startIcon={<EditIcon />} className="text-indigo-500 hover:text-indigo-700">
              Edit
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableMemo;