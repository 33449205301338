import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { employeeApi } from "./apis/EmployeeApi";
import { departmentApi } from "./apis/DepartmentApi";
import { documentNewsApi } from "./apis/DocumentsNews";
import { assessmentApi } from "./apis/AssessmentApi";
import { notificationApi } from "./apis/NotificationApi";
import { clientApi } from "./apis/ClientApi";
import { memoEventApi } from "./apis/MemoEvents";
import { documentApi } from "./apis/DocumentsApi";
import { addressApi } from "./apis/AddressApi";

export const store = configureStore({
  reducer: {
    [employeeApi.reducerPath]: employeeApi.reducer,
    [departmentApi.reducerPath]: departmentApi.reducer,
    [documentNewsApi.reducerPath]: documentNewsApi.reducer,
    [assessmentApi.reducerPath]: assessmentApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
    [memoEventApi.reducerPath]: memoEventApi.reducer,
    [documentApi.reducerPath]: documentApi.reducer,
    [addressApi.reducerPath]: addressApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      employeeApi.middleware,
      departmentApi.middleware,
      documentNewsApi.middleware,
      assessmentApi.middleware,
      notificationApi.middleware,
      clientApi.middleware,
      memoEventApi.middleware,
      documentApi.middleware,
      addressApi.middleware
    );
  },
});

setupListeners(store.dispatch);
export {
  useLazyFetchEmployeeQuery,
  useAddEmployeeMutation,
  useEmployeeDetailedQuery,
  useEmployeeDetailedEditMutation,
  useEmployeebadgesQuery,
  useEmployeeAssignedAssessmentMutation,
  useEmployeeAttendanceMutation,
  useEmployeeAttendanceQuery,
  useEmployeeEducationalBackgroundQuery,
  useEmployeeDepartmentQuery,
  useEmployeeTeamMembersQuery,
} from "./apis/EmployeeApi";
export { useFetchDepartmentQuery } from "./apis/DepartmentApi";
export {
  useAddDocumentsMutation,
  useFetchDocumentsPageQuery,
  useAddNewsMutation,
  useFetchNewsPageQuery,
  useGetDocumentFilesQuery,
} from "./apis/DocumentsNews";
export {
  useFetchEmployeeAssessmentQuery,
  useEmployeeAssDetailedQuery,
  useFetchAssessmentQuery,
  useFetchEmployeeAssessmentSectionQuery,
  useEmployeePageActionMutation,
  useFetchEmployeePageDetailedQuery,
} from "./apis/AssessmentApi";
export { useSendFeedbackMutation } from "./apis/NotificationApi";
export {
  useClientAssessmentQuery,
  useEmployeeClientAssessmentMutation,
  useRegionListQuery,
  useProvinceListQuery,
  useCityListQuery,
  useBarangayListQuery,
  useFetchManagerQuery,
  useFetchSupervisorQuery,
  useFetchLevelQuery,
  useFetchJobTitlesQuery,
} from "./apis/ClientApi";
export {
  useAddMemoMutation,
  useMemoListPageQuery,
  useMemoDetailedQuery,
  useMemoListOthersPageQuery,
  useAddEventMutation,
  useEventCategoryQuery,
} from "./apis/MemoEvents";
export {
  useAddNewDocumentsMutation,
  useFetchDocumentListQuery,
  useAddNewDocumentFilesMutation,
  useLazyFetchDetailedDocumentQuery,
} from "./apis/DocumentsApi";

export {
  useLazyFetchRegionsQuery,
  useLazyFetchProvinceQuery,
  useLazyFetchCityQuery,
  useLazyFetchBarangayQuery,
} from "./apis/AddressApi";
