import { InboxOutlined } from "@ant-design/icons";
import {
  Input,
  InputNumber,
  Select,
  Space,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import React, { ChangeEvent, useState } from "react";
import { useMultiStep } from "../../context/StepContext";

const AddNewEmployeeEducation = () => {
  const { Text } = Typography;
  const { empInputs, setEmpInputs } = useMultiStep();
  const [educCat, setEducCat] = useState<number | null>(null);
  const props: UploadProps = {
    name: "file",
    multiple: true,
    beforeUpload: () => false,
    showUploadList: true,
    onChange(info) {
      setEmpInputs((prev) => ({
        ...prev,
        fileEduc: info.fileList.map((file) => file.originFileObj as File),
      }));
    },
    onDrop(e) {
      setEmpInputs((prevState) => ({
        ...prevState,
        files: Array.from(e.dataTransfer.files),
      }));
    },
  };

  const fieldsOfStudy = [
    { value: "biology", label: "Biology" },
    { value: "chemistry", label: "Chemistry" },
    { value: "physics", label: "Physics" },
    { value: "environmental_science", label: "Environmental Science" },
    { value: "mechanical_engineering", label: "Mechanical Engineering" },
    { value: "electrical_engineering", label: "Electrical Engineering" },
    { value: "civil_engineering", label: "Civil Engineering" },
    { value: "computer_engineering", label: "Computer Engineering" },
    { value: "information_technology", label: "Information Technology" },
    { value: "computer_science", label: "Computer Science" },
    { value: "cybersecurity", label: "Cybersecurity" },
    { value: "software_development", label: "Software Development" },
    { value: "applied_mathematics", label: "Applied Mathematics" },
    { value: "pure_mathematics", label: "Pure Mathematics" },
    { value: "statistics", label: "Statistics" },
    { value: "medicine", label: "Medicine" },
    { value: "nursing", label: "Nursing" },
    { value: "pharmacy", label: "Pharmacy" },
    { value: "public_health", label: "Public Health" },
    { value: "finance", label: "Finance" },
    { value: "accounting", label: "Accounting" },
    { value: "marketing", label: "Marketing" },
    { value: "human_resources", label: "Human Resources" },
    { value: "criminal_law", label: "Criminal Law" },
    { value: "corporate_law", label: "Corporate Law" },
    { value: "international_law", label: "International Law" },
    { value: "primary_education", label: "Primary Education" },
    { value: "secondary_education", label: "Secondary Education" },
    { value: "early_childhood_education", label: "Early Childhood Education" },
    { value: "special_education", label: "Special Education" },
    { value: "educational_psychology", label: "Educational Psychology" },
    { value: "economics", label: "Economics" },
    { value: "sociology", label: "Sociology" },
    { value: "psychology", label: "Psychology" },
    { value: "history", label: "History" },
    { value: "political_science", label: "Political Science" },
    { value: "anthropology", label: "Anthropology" },
    { value: "philosophy", label: "Philosophy" },
    { value: "literature", label: "Literature" },
    { value: "graphic_design", label: "Graphic Design" },
    { value: "fine_arts", label: "Fine Arts" },
    { value: "music", label: "Music" },
    { value: "architecture", label: "Architecture" },
    { value: "interior_design", label: "Interior Design" },
    { value: "animation", label: "Animation" },
    { value: "agriculture", label: "Agriculture" },
    { value: "marine_biology", label: "Marine Biology" },
    { value: "urban_planning", label: "Urban Planning" },
    { value: "journalism", label: "Journalism" },
    { value: "environmental_management", label: "Environmental Management" },
    { value: "artificial_intelligence", label: "Artificial Intelligence" },
    { value: "biotechnology", label: "Biotechnology" },
  ];

  const institutionsInPhilippines = [
    {
      value: "university_of_the_philippines",
      label: "University of the Philippines (UP)",
    },
    {
      value: "ateneo_de_manila_university",
      label: "Ateneo de Manila University",
    },
    { value: "de_la_salle_university", label: "De La Salle University (DLSU)" },
    {
      value: "university_of_santo_tomas",
      label: "University of Santo Tomas (UST)",
    },
    {
      value: "polytechnic_university_of_the_philippines",
      label: "Polytechnic University of the Philippines (PUP)",
    },
    { value: "mapua_university", label: "Mapúa University" },
    { value: "far_eastern_university", label: "Far Eastern University (FEU)" },
    {
      value: "technological_institute_of_the_philippines",
      label: "Technological Institute of the Philippines (TIP)",
    },
    { value: "adamson_university", label: "Adamson University" },
    { value: "university_of_the_east", label: "University of the East (UE)" },
    { value: "silliman_university", label: "Silliman University" },
    {
      value: "xavier_university",
      label: "Xavier University - Ateneo de Cagayan",
    },
    {
      value: "cebu_technological_university",
      label: "Cebu Technological University",
    },
    {
      value: "mindanao_state_university",
      label: "Mindanao State University (MSU)",
    },
    { value: "san_beda_university", label: "San Beda University" },
    {
      value: "philrice",
      label: "Philippine Rice Research Institute (PhilRice)",
    },
    {
      value: "pagasa",
      label:
        "Philippine Atmospheric, Geophysical and Astronomical Services Administration (PAGASA)",
    },
    { value: "irri", label: "International Rice Research Institute (IRRI)" },
    {
      value: "asti",
      label: "Advanced Science and Technology Institute (ASTI)",
    },
    { value: "pgh", label: "Philippine General Hospital (PGH)" },
    { value: "makati_medical_center", label: "Makati Medical Center" },
    { value: "st_lukes_medical_center", label: "St. Luke's Medical Center" },
    { value: "asian_hospital", label: "Asian Hospital and Medical Center" },
    { value: "the_medical_city", label: "The Medical City" },
    { value: "dost", label: "Department of Science and Technology (DOST)" },
    {
      value: "tesda",
      label: "Technical Education and Skills Development Authority (TESDA)",
    },
    { value: "ched", label: "Commission on Higher Education (CHED)" },
    {
      value: "neda",
      label: "National Economic and Development Authority (NEDA)",
    },
    {
      value: "don_bosco_technical_institute",
      label: "Don Bosco Technical Institute",
    },
    { value: "mfi_polytechnic_institute", label: "MFI Polytechnic Institute" },
    {
      value: "asian_institute_of_maritime_studies",
      label: "Asian Institute of Maritime Studies",
    },
    {
      value: "tesda_regional_training_centers",
      label: "TESDA Regional Training Centers",
    },
  ];

  const educationLevels = [
    { value: 1, label: "Primary Education" },
    { value: 2, label: "Secondary Education" },
    { value: 3, label: "Tertiary Education" },
  ];

  const handleSelectChange = (
    val: number | null,
    label: string | number,
    name: string
  ) => {
    setEmpInputs((prev) => ({
      ...prev,
      [name]: label,
    }));
    if (name === "highestEduc") {
      setEducCat(val);
    }
  };

  const handleChangeInput = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEmpInputs((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <>
      <Space className="block mt-10 mb-8 space-y-5 mx-16">
        <Text className=" font-sans text-2xl font-bold text-[#0056D2]">
          Educational Attainment
        </Text>
        <Space className="block mt-10 mb-8 space-y-5">
          <Space className=" grid grid-flow-row grid-cols-3 gap-10">
            <div className="flex flex-col">
              <Text className="font-sans">Highest Educational Attainment</Text>
              <Select
                onChange={(value, option) => {
                  let label = "";
                  if (Array.isArray(option)) {
                    label = String(option[0]?.label || "");
                  } else {
                    label = String(option?.label || "");
                  }
                  handleSelectChange(Number(value), value, "highestEduc");
                }}
                value={empInputs.highestEduc}
                placeholder="Select Highest Education"
                style={{ width: 330 }}
                className=" font-sans text-sm custom-style-input mt-2"
                options={educationLevels}
              ></Select>
            </div>
            <div className="flex flex-col">
              <Text className="font-sans">Institution Name</Text>
              {educCat === 3 ? (
                <Select
                  onChange={(value, option) => {
                    let label = "";
                    if (Array.isArray(option)) {
                      label = String(option[0]?.label || "");
                    } else {
                      label = String(option?.label || "");
                    }
                    handleSelectChange(Number(value), label, "institutionName");
                  }}
                  value={empInputs.institutionName}
                  placeholder="Select Institution"
                  style={{ width: 330 }}
                  className=" font-sans text-sm custom-style-input mt-2"
                  options={institutionsInPhilippines}
                ></Select>
              ) : (
                <Input
                  name="institutionName"
                  value={empInputs.institutionName ?? ""}
                  placeholder="Name"
                  onChange={handleChangeInput}
                  className="custom-style-input mt-2"
                />
              )}
            </div>
            {educCat === 3 && (
              <>
                <div className="flex flex-col">
                  <Text className="font-sans">Degree</Text>
                  <InputNumber
                    name="degree"
                    value={empInputs.degree ?? undefined}
                    placeholder="Degree. 1 .. 4"
                    onChange={(value) => {
                      setEmpInputs((prev) => ({
                        ...prev,
                        degree: value,
                      }));
                    }}
                    className="custom-style-input mt-2"
                    min={1}
                    max={4}
                  />
                </div>
                <div className="flex flex-col">
                  <Text className="font-sans">Field of Study</Text>
                  <Select
                    onChange={(value, option) => {
                      let label = "";
                      if (Array.isArray(option)) {
                        label = String(option[0]?.label || "");
                      } else {
                        label = String(option?.label || "");
                      }
                      handleSelectChange(Number(value), label, "fieldOfStudy");
                    }}
                    value={empInputs.fieldOfStudy}
                    placeholder="Select Field of Study"
                    style={{ width: 330 }}
                    className=" font-sans text-sm custom-style-input mt-2"
                    options={fieldsOfStudy}
                  ></Select>
                </div>
              </>
            )}
            <div className="flex flex-col">
              <Text className="font-sans">Date Graduated</Text>
              <Input
                onChange={handleChangeInput}
                value={empInputs.dateGrad ?? ""}
                name="dateGrad"
                type="date"
                className="custom-style-input mt-2"
              />
            </div>
          </Space>
          <Text className="font-sans">Institution Address</Text>
          <Input.TextArea
            onChange={handleChangeInput}
            value={empInputs.instAddress ?? ""}
            name="instAddress"
            rows={4}
            placeholder="Enter your location"
            className="custom-style-input"
          />
        </Space>
        <div>
          <Upload.Dragger {...props} listType="picture">
            <InboxOutlined className=" text-6xl text-[#cfd413]" />
            <Typography.Text className="block text-base font-sans">
              Click or drag file to this area to upload
            </Typography.Text>
            <Typography.Text className="block text-sm text-slate-500 font-sans">
              Upload your file by clicking here or dragging it into this area.
            </Typography.Text>
          </Upload.Dragger>
        </div>
      </Space>
    </>
  );
};

export default AddNewEmployeeEducation;
