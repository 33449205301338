import React, { useEffect, useState } from "react";
import { Table, Input, Pagination } from "antd";
import type { PaginationProps, TableColumnsType } from "antd";

import { BsSearch } from "react-icons/bs";
import { useLazyFetchEmployeeQuery } from "../../../../store";
import { Employee } from "./Type";
import EmployeeIcon from "../../../reuseables/EmployeeIcon";
import { format } from "date-fns";

const fileStatus = ["Complete", "Incomplete", "Not Applicable"];
type eventTypes = React.KeyboardEventHandler<HTMLInputElement> | any;

export default function TwoOOne() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [pageSize, setPageSize] = useState(10);

  // search function
  const handleSearchChange = (event: eventTypes) => {
    setSearch(event.target.value);
    setPage(1);
  };

  const [
    trigger,
    { data: fetchEmployee, isLoading, isFetching, isSuccess, isError, error },
  ] = useLazyFetchEmployeeQuery();

  useEffect(() => {
    trigger({ page, pageSize });
    if (isLoading) {
      console.log("now loading..");
    } else {
      console.log("success");
      console.log(fetchEmployee);
    }
  }, [trigger, page, pageSize, isLoading, isSuccess]);

  let content: React.ReactNode = <></>;
  if (isLoading) {
    content = <div>Loading...</div>;
  } else if (isError) {
    content = <div>Something went wrong</div>;
  } else {
    const dataTable = fetchEmployee?.data.results.map(
      (item: any, index: number) => ({
        id: item.id,
        lastName: item.user?.last_name,
        firstName: item.user?.first_name,
        midName: item?.user?.middle_name,
        manager: item?.manager,
        supervisor: item?.supervisor,
        workArrangement: ["Work From Home", "Onsite", "Hybrid"][
          item?.work_arrangement - 1
        ],
        empType: ["Full time", "Part-Time", "Project-Based", "Contractual"][
          item?.employee_status - 1
        ],
        empStatus: [
          "Active",
          "Suspended",
          "Deleted",
          "Incomplete Profile",
          "For Activation",
        ][item?.status - 1],
        contractStartDate: format(item?.ctrctStartDate, "MMM dd, yyyy"),
        ctrctEndDate: format(item?.ctrctEndDate, "MMM dd, yyyy"),
        regularization: format(item?.rglrzation, "MMM dd, yyyy"),
        daysUntilReg: "",
        daysUntilEndContract: "",
        status: "",
        personalDatasheet: "NA",
        level: item.level?.name,
        position: item.job_title?.name,
        department: item.department?.name,
        // profile: item.profile_image,
        profile: `https://randomuser.me/api/portraits/${
          ["men", "women"][Number(item?.gender - 1) ?? 1]
        }/${index}.jpg`,
        nbipoliceClearance: fileStatus[3 - 1],
        medCert: fileStatus[3 - 1],
        coe: fileStatus[3 - 1],
        brgyClearance: fileStatus[3 - 1],
        dpndBirthCert: fileStatus[3 - 1],
      })
    );
    const columns: TableColumnsType<Employee> = [
      {
        key: "profile",
        fixed: "left",
        dataIndex: "profile",
        title: () => <p className="font-sans text-zinc-700"></p>,
        render: (profile) => <EmployeeIcon profile={profile} />,
      },
      {
        key: "id",
        dataIndex: "id",
        fixed: "left",
        title: () => <p className="font-sans text-zinc-700">Employee ID</p>,
      },
      {
        key: "lastName",
        dataIndex: "lastName",
        fixed: "left",
        title: () => <p className="font-sans text-zinc-700">Last Name</p>,
      },
      {
        key: "firstName",
        dataIndex: "firstName",
        fixed: "left",
        title: () => <p className="font-sans text-zinc-700">First Name</p>,
      },
      {
        key: "midName",
        dataIndex: "midName",
        fixed: "left",
        title: () => <p className="font-sans text-zinc-700">Middle Name</p>,
      },
      {
        key: "supervisor",
        dataIndex: "supervisor",
        title: () => <p className="font-sans text-zinc-700">Supervisor</p>,
      },
      {
        dataIndex: "workArrangement",
        key: "workArrangement",
        title: () => (
          <p className="font-sans text-zinc-700">Work Arrangement</p>
        ),
      },
      {
        dataIndex: "empType",
        key: "empType",
        title: () => <p className="font-sans text-zinc-700">Employment Type</p>,
      },
      {
        dataIndex: "empStatus",
        key: "empStatus",
        title: () => (
          <p className="font-sans text-zinc-700">Employment Status</p>
        ),
      },
      {
        dataIndex: "contractStartDate",
        key: "contractStartDate",
        title: () => (
          <p className="font-sans text-zinc-700">Contract Start Date</p>
        ),
      },
      {
        dataIndex: "contractEndDate",
        key: "contractEndDate",
        title: () => (
          <p className="font-sans text-zinc-700">Contract End Date</p>
        ),
      },
      {
        dataIndex: "regularization",
        key: "regularization",
        title: () => <p className="font-sans text-zinc-700">Regularization</p>,
      },
      {
        dataIndex: "daysUntilReg",
        key: "daysUntilReg",
        title: () => (
          <p className="font-sans text-zinc-700">Days until regularization</p>
        ),
      },
      {
        dataIndex: "daysUntilEndContract",
        key: "daysUntilEndContract",
        title: () => (
          <p className="font-sans text-zinc-700">Days until end of contract</p>
        ),
      },
      {
        dataIndex: "status",
        key: "status",
        title: () => <p className="font-sans text-zinc-700">Status</p>,
      },
      {
        dataIndex: "personalDatasheet",
        key: "personalDatasheet",
        title: () => (
          <p className="font-sans text-zinc-700">Personal Data Sheet</p>
        ),
      },
      {
        dataIndex: "level",
        key: "level",
        title: () => <p className="font-sans text-zinc-700">Level</p>,
      },
      {
        dataIndex: "position",
        key: "position",
        title: () => <p className="font-sans text-zinc-700">Position</p>,
      },
      {
        dataIndex: "department",
        key: "department",
        title: () => <p className="font-sans text-zinc-700">Department</p>,
      },
      {
        dataIndex: "nbipoliceClearance",
        key: "nbipoliceClearance",
        title: () => (
          <p className="font-sans text-zinc-700">
            Valid NBI / Police Clearance
          </p>
        ),
      },
      {
        dataIndex: "medCert",
        key: "medCert",
        title: () => (
          <p className="font-sans text-zinc-700">Medical Certificate</p>
        ),
      },
      {
        dataIndex: "coe",
        key: "coe",
        title: () => (
          <p className="font-sans text-zinc-700">Certificate of Employment</p>
        ),
      },
      {
        dataIndex: "brgyClearance",
        key: "brgyClearance",
        title: () => (
          <p className="font-sans text-zinc-700">Barangay Clearance</p>
        ),
      },
      {
        dataIndex: "dpndBirthCert",
        key: "dpndBirthCert",
        title: () => (
          <p className="font-sans text-zinc-700">
            Dependents' Birth Certificate
          </p>
        ),
      },
    ];
    content = (
      <>
        <Table
          style={{ borderBlockColor: "#d4d4d8" }}
          bordered
          rowKey="id"
          pagination={false}
          columns={columns}
          scroll={{ x: "max-content" }}
          dataSource={dataTable}
          rowClassName=" font-sans text-neutral-600"
        />
        <Pagination
          className="flex justify-end"
          current={page}
          onChange={(page, pageSize) => {
            setPageSize(pageSize);
            setPage(page);
          }}
          total={fetchEmployee?.data?.count}
          defaultPageSize={pageSize}
        />
      </>
    );
  }

  return (
    <div>
      {" "}
      <div className=" mt-10 space-y-5">
        <div className=" flex items-center justify-between">
          <Input
            placeholder="Search.."
            defaultValue={search}
            onKeyUp={handleSearchChange}
            className="custom-style-input w-72"
            suffix={<BsSearch />}
          />
        </div>
        {content}
      </div>
    </div>
  );
}
