import {
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Typography,
} from "antd";
import { useState } from "react";
import { useMultiStep } from "../../context/StepContext";
import {
  useFetchDepartmentQuery,
  useFetchJobTitlesQuery,
  useFetchLevelQuery,
  useFetchManagerQuery,
  useFetchSupervisorQuery,
} from "../../store";

type OptionsType = {
  value: number;
  label: string;
};

type InputArr = {
  id: number;
  label: string;
  options: OptionsType[];
  placeholder: string;
  inputName: string;
};

type Inputs = {
  empStatus: string | number;
  level: number;
  empJobPos: number | string;
  empDepartment: number | string;
  empWorkArg: number | string;
};
const AddNewEmployeeInformation = () => {
  const [employementStatus, setEmploymentStatus] = useState(0);
  const { Text } = Typography;
  const { empInputs, setEmpInputs } = useMultiStep();
  const {
    data: fetchSupervisor,
    isFetching: isFetchingSup,
    isLoading: isLoadingSup,
    isError: isErrorSup,
  } = useFetchSupervisorQuery("");

  const {
    data: fetchManager,
    isFetching: isFetchingMngr,
    isLoading: isLoadingMngr,
    isError: isErrorMngr,
  } = useFetchManagerQuery("");

  const {
    data: fetchDepartment,
    isFetching: isFetchingDept,
    isLoading: isLoadingDept,
    isError: isErrorDept,
  } = useFetchDepartmentQuery("");

  const {
    data: fetchLevels,
    isFetching: isFetchingLvl,
    isLoading: isLoadingLvl,
    isError: isErrorLvl,
  } = useFetchLevelQuery("");

  const {
    data: fetchJobTitles,
    isFetching: isFetchingJobs,
    isLoading: isLoadingJobs,
    isError: isErrorJob,
  } = useFetchJobTitlesQuery("");
  let supervisorOptions = [];
  if (
    !isLoadingMngr &&
    !isLoadingSup &&
    !isLoadingDept &&
    !isLoadingLvl &&
    !isLoadingJobs
  ) {
    console.log(fetchManager);
    console.log(fetchSupervisor);
    console.log(fetchLevels);
    console.log(fetchJobTitles);
    supervisorOptions = fetchSupervisor?.data.map((item: any) => ({
      value: item.id,
      label: `${item.user.first_name} ${item.user.last_name}`,
    }));
  } else {
    console.log("loading...");
  }
  const SelectInputArr: InputArr[] = [
    {
      id: 1,
      options: fetchLevels?.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      })),
      label: "Level",
      placeholder: "Select Level",
      inputName: "level",
    },
    {
      id: 2,
      options: fetchJobTitles?.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      })),
      label: "Position",

      placeholder: "Select Employee Position",
      inputName: "empJobPos",
    },
    {
      id: 3,
      options: fetchDepartment?.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      })),
      label: "Department",
      placeholder: "Select Department",
      inputName: "empDepartment",
    },
    {
      id: 4,
      options: [
        { value: 1, label: "Hybrid" },
        { value: 2, label: "Onsite" },
        { value: 3, label: "Work From Home" },
      ],
      label: "Work Arrangement",
      placeholder: "Choose Work Arrangement",
      inputName: "empWorkArg",
    },
  ];

  // handle change the radio input
  const handleRadioChange = (e: RadioChangeEvent) => {
    setEmpInputs((prev) => ({ ...prev, empStatus: e.target.value }));
  };

  const handleSelectChange = (
    val: number | null,
    label: string | number,
    name: string
  ) => {
    setEmpInputs((prev) => ({
      ...prev,
      [name]: label,
    }));
  };

  const handleChangeInput = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setEmpInputs((prev) => ({ ...prev, [name]: value }));
  };
  return (
    <>
      <Space className="block mt-10 mb-8 space-y-5 mx-16">
        <Radio.Group
          onChange={handleRadioChange}
          value={empInputs.empStatus}
          className=" flex justify-center items-center space-x-28 py-5"
        >
          <Radio className=" font-sans" value={1}>
            Full-Time
          </Radio>
          <Radio className=" font-sans" value={2}>
            Part-Time
          </Radio>
          <Radio className=" font-sans" value={3}>
            Project-Based
          </Radio>
          <Radio className=" font-sans" value={4}>
            Contractual
          </Radio>
        </Radio.Group>
        <Text className=" font-sans text-2xl font-bold text-[#0056D2]">
          Employment Details
        </Text>
        <Space className=" grid grid-flow-row grid-cols-3 gap-10 pb-5">
          <div className="flex flex-col">
            <Text className="font-sans">Employee ID</Text>
            <Input
              placeholder="Ex. 123"
              className="custom-style-input mt-2"
              value={empInputs.empId}
              disabled
            />
          </div>
          {SelectInputArr.map((entry) => {
            return (
              <div className="flex flex-col" key={entry.id}>
                <Text className="font-sans">{entry.label}</Text>
                <Select
                  className=" font-sans text-sm mt-2"
                  placeholder={entry.placeholder}
                  options={entry.options}
                  value={empInputs[entry.inputName as keyof Inputs]}
                  onChange={(value, option) => {
                    let label = "";
                    if (Array.isArray(option)) {
                      label = String(option[0]?.label || "");
                    } else {
                      label = String(option?.label || "");
                    }
                    handleSelectChange(Number(value), value, entry.inputName);
                  }}
                />
              </div>
            );
          })}
          <div className="flex flex-col">
            <Text className="font-sans">Manager</Text>
            <Select
              className=" font-sans text-sm mt-2"
              placeholder="Select Manager"
              options={fetchManager?.data.map((item: any) => ({
                value: item.id,
                label: `${item.user.first_name} ${item.user.last_name}`,
              }))}
              value={empInputs.empManager}
              onChange={(value, option) => {
                let label = "";
                if (Array.isArray(option)) {
                  label = String(option[0]?.label || "");
                } else {
                  label = String(option?.label || "");
                }
                handleSelectChange(Number(value), Number(value), "empManager");
              }}
            ></Select>
          </div>
          <div className="flex flex-col">
            <Text className="font-sans">Supervisor</Text>
            <Select
              className=" font-sans text-sm mt-2"
              placeholder="Select Manager"
              options={supervisorOptions}
              value={empInputs.supervisor}
              onChange={(value, option) => {
                let label = "";
                if (Array.isArray(option)) {
                  label = String(option[0]?.label || "");
                } else {
                  label = String(option?.label || "");
                }
                handleSelectChange(Number(value), Number(value), "supervisor");
              }}
            ></Select>
          </div>
          <div className="flex flex-col">
            <Text className="font-sans">Date Hired</Text>
            <Input
              type="date"
              className="custom-style-input mt-2"
              name="dateHired"
              value={empInputs.dateHired ?? ""}
              onChange={handleChangeInput}
            />
          </div>
          <div className="flex flex-col">
            <Text className="font-sans">Contract Start Date</Text>
            <Input
              type="date"
              className="custom-style-input mt-2"
              name="contractStartDate"
              value={empInputs.contractStartDate ?? ""}
              onChange={handleChangeInput}
            />
          </div>
          <div className="flex flex-col">
            <Text className="font-sans">Contract End Date</Text>
            <Input
              type="date"
              className="custom-style-input mt-2"
              name="contractEndDate"
              value={empInputs.contractEndDate ?? ""}
              onChange={handleChangeInput}
            />
          </div>
          <div className="flex flex-col">
            <Text className="font-sans">Regularization</Text>
            <Input
              type="date"
              className="custom-style-input mt-2"
              name="regularization"
              value={empInputs.regularization ?? ""}
              onChange={handleChangeInput}
            />
          </div>
        </Space>
        <Text className=" font-sans text-2xl font-bold text-[#0056D2]">
          Salary Details
        </Text>
        <Space className=" grid grid-flow-row grid-cols-3 gap-10">
          <div className="flex flex-col">
            <Text className="font-sans">Basic Salary</Text>
            <Input
              className="custom-style-input mt-2"
              name="basicSalary"
              value={empInputs.basicSalary ?? ""}
              onChange={handleChangeInput}
            />
          </div>
          <div className="flex flex-col">
            <Text className="font-sans">Allowance</Text>
            <Input
              className="custom-style-input mt-2"
              name="allowance"
              value={empInputs.allowance ?? ""}
              onChange={handleChangeInput}
            />
          </div>
          <div className="flex flex-col">
            <Text className="font-sans">Total Salary</Text>
            <Input
              className="custom-style-input mt-2"
              name="totalSalary"
              value={
                Number(empInputs.basicSalary) + Number(empInputs.allowance)
              }
              onChange={handleChangeInput}
            />
          </div>
        </Space>
      </Space>
    </>
  );
};

export default AddNewEmployeeInformation;
