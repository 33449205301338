import { Card } from "antd";
import React, { useMemo } from "react";
import ReactVideoThumbnail from "react-video-thumbnail";
import "./CardStyle.css";
const apiDomain = process.env.REACT_APP_API_DOMAIN;

const VideoThumbnails = ({ videoUrl }: { videoUrl: string }) => {
  const uniqueVideoUrl = `${videoUrl}?t=${Math.floor(Math.random() * 4)}`;
  console.log(uniqueVideoUrl);

  return (
    <Card className="no-padding-card mt-auto">
      <ReactVideoThumbnail
        key={uniqueVideoUrl}
        videoUrl={`${apiDomain}${videoUrl}`}
        onThumbnailReady={(thumbnail: any) => console.log(thumbnail)}
        width={300}
      />
    </Card>
  );
};

export default VideoThumbnails;
