import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");
const clientFromStorage: any = localStorage.getItem("employee_detail");
const clientObj = JSON.parse(clientFromStorage);
const client = clientObj?.client?.id;


const memoEventApi = createApi({
  reducerPath: "memo_event",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  tagTypes: ["memo_event"],
  endpoints(builder) {
    return {
      addMemo: builder.mutation({
        query: (data) => {
          return {
            url: `/api/memodocs/memo_event/memo_add/`,
            headers: {
              Authorization: "token " + token,
            },
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["memo_event"],
      }),
      memoListPage: builder.query({
        query: ({ page, pageSize, search }) => {
          return {
            url: `/api/memodocs/memo_event/mome_page_list/?client_id=${client}&page=${page}&page_size=${pageSize}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
        providesTags: ["memo_event"],
      }),
      memoListOthersPage: builder.query({
        query: ({ page, pageSize, id }) => {
          return {
            url: `/api/memodocs/memo_event/mome_page_others_list/?client_id=${client}&exclude_id=${id}&page=${page}&page_size=${pageSize}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
        providesTags: ["memo_event"],
      }),
      memoDetailed: builder.query({
        query: ({ id }) => {
          return {
            url: `/api/memodocs/memo_event/${id}/memo_detail/?client_id=${client}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
        providesTags: ["memo_event"],
      }),
      addEvent: builder.mutation({
        query: (data) => {
          return {
            url: `/api/memodocs/memo_event/event_add/`,
            headers: {
              Authorization: "token " + token,
            },
            body: data,
            method: "POST",
          };
        },
        invalidatesTags: ["memo_event"],
      }),
      eventCategory: builder.query({
        query: () => {
          return {
            url: `/api/memodocs/memo_event/event_category_list/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
        providesTags: ["memo_event"],
      }),
    };
  },
});
export const {
  useMemoListPageQuery,
  useAddMemoMutation,
  useMemoDetailedQuery,
  useMemoListOthersPageQuery,
  useAddEventMutation,
  useEventCategoryQuery,
} = memoEventApi;
export { memoEventApi };