import React, { useEffect, useRef, useState } from "react";
import { FaFolder } from "react-icons/fa6";
import { HiOutlineDotsVertical } from "react-icons/hi";
import TextThumbnail from "./OtherDocsComponent/TextThumbnail";
import ImageThumbnail from "./OtherDocsComponent/ImageThumbnail";
import PDFThumbnail from "./OtherDocsComponent/PDFThumbnail";
import { Input } from "antd";
import { BsSearch } from "react-icons/bs";

import { VscFilePdf } from "react-icons/vsc";
import { IoMdImages } from "react-icons/io";
import { IoDocumentTextOutline } from "react-icons/io5";
import { PiVideoFill } from "react-icons/pi";
import VideoThumbnails from "./OtherDocsComponent/VideoThumbnails";
import AddNewDocuments from "./OtherDocsComponent/AddNewDocuments";
import { ToastContainer } from "react-toastify";
import {
  useFetchDocumentListQuery,
  useLazyFetchDetailedDocumentQuery,
} from "../../../../store";
import AddNewFile from "./OtherDocsComponent/AddNewFile";

type eventTypes = React.KeyboardEventHandler<HTMLInputElement> | any;

type Files = {
  title: string;
  files: string[];
  description?: string;
  id: number;
};
type DocDataFiles = {
  ftp: string;
};
export default function OtherDocs() {
  const [search, setSearch] = useState<string>("");
  const [folder, setFolder] = useState<number | null>(null);
  const [files, setFiles] = useState<Files | null>(null);
  const [fileDocs, setFileDocs] = useState(() => {
    const setLocal = localStorage.getItem("fileDocs");
    return setLocal ? JSON.parse(setLocal) : [{ folderName: "", files: [] }];
  });
  const [currOpen, setCurrOpen] = useState<number | null>(null);
  const [openedFolder, setOpenedFolder] = useState<number | null>(null);
  const [clientId, setClientID] = useState(() => {
    const storedClientId: any = localStorage.getItem("user_info");
    const clientObj = JSON.parse(storedClientId);
    const client = clientObj?.client?.id;
    return client ? client : 1;
  });

  const [userId, setUserId] = useState(() => {
    const storedUserId: any = localStorage.getItem("user_info");
    const userObj = JSON.parse(storedUserId);
    const user = userObj?.id;
    return user ? user : null;
  });

  const {
    data: docDataList,
    error,
    isError,
    isLoading,
    isFetching,
  } = useFetchDocumentListQuery({ client: 1 });

  const [
    trigger,
    {
      data: docDataFiles,
      isSuccess,
      isFetching: isFetchDocFiles,
      isError: isErrDocFiles,
    },
  ] = useLazyFetchDetailedDocumentQuery();

  let content;
  // useEffect(() => {
  //   trigger({ client: clientId, id: currOpen });
  //   console.log(docDataFiles);
  // }, [trigger, currOpen]);

  if (!isLoading && isFetching) {
    content = <div>Loading...</div>;
  } else if (isError) {
    content = <div>Something went wrong... ({`${error}`})</div>;
  } else {
    content = docDataList?.data.map(
      ({ title, files, id }: Files, index: number) => (
        <FolderOption
          key={`item-${index}`}
          index={index}
          item={title}
          onFetch={trigger}
          id={id}
          currOpen={currOpen}
          getFolder={setFolder}
          setCurrOpen={setCurrOpen}
          getOpenedFolder={setOpenedFolder}
        />
      )
    );
  }
  // search function
  const handleSearchChange = (event: eventTypes) => {
    setSearch(event.target.value);
  };

  function getFileExtension(filename: string): string | null {
    const index = filename.lastIndexOf(".");
    return index > 0 ? filename.substring(index + 1) : null;
  }

  function getFileName(filename: string): string | null {
    const indexSlash = filename.lastIndexOf("/");
    const indexDot = filename.lastIndexOf(".");
    if (indexSlash >= -1) {
      console.log(filename);
      const nameWithExtension = filename.substring(indexSlash + 1);
      return indexDot > indexSlash
        ? nameWithExtension.substring(0, nameWithExtension.lastIndexOf("."))
        : nameWithExtension;
    }

    return null;
  }

  function getIconColor(extension: string) {
    return getFileExtension(extension) === "pdf" ? (
      <div className="p-2 rounded-md bg-red-200">
        <span className="text-red-400 text-2xl ">
          <VscFilePdf />
        </span>
      </div>
    ) : getFileExtension(extension) === "png" ||
      getFileExtension(extension) === "jpg" ? (
      <div className="rounded-md p-2 bg-green-200">
        <span className="text-green-400 text-2xl">
          <IoMdImages />
        </span>
      </div>
    ) : getFileExtension(extension) === "mp4" ? (
      <div className="rounded-md p-2 bg-orange-200">
        <span className="text-orange-400 text-2xl">
          <PiVideoFill />
        </span>
      </div>
    ) : (
      <div className="rounded-md p-2 bg-blue-200">
        <span className="text-blue-400 text-2xl">
          <IoDocumentTextOutline />
        </span>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-md px-8 py-4 mt-4">
      <div className="">
        <h2 className="text-2xl font-semibold">Folders</h2>
      </div>
      <div className=" flex items-center justify-between">
        <Input
          placeholder="Search.."
          defaultValue={search}
          onKeyUp={handleSearchChange}
          className="custom-style-input w-72"
          suffix={<BsSearch />}
        />

        <div>
          <AddNewDocuments type={1} clientId={clientId} userId={userId} />
          <ToastContainer />
        </div>
      </div>
      <div className="flex gap-6 my-8 flex-wrap">{fileDocs && content}</div>
      <hr className="h-[2px] bg-gray-300 mt-12 mb-12 border-0" />
      <div className="mt-8 flex justify-between">
        <h2 className="text-2xl font-semibold">Files</h2>

        {docDataFiles && (
          <div>
            <AddNewFile
              type={1}
              clientId={clientId}
              userId={userId}
              fileOpen={openedFolder}
            />
          </div>
        )}
      </div>
      <div className="flex gap-6 flex-wrap mt-4 mb-12">
        {docDataFiles &&
          docDataFiles.data.files?.map(
            ({ ftp }: { ftp: string }, index: number) => {
              return (
                <div
                  key={`${index}-itemskey`}
                  className="w-72 min-h-72 rounded-t-md bg-gray-100 border border-gray-200 flex flex-col"
                >
                  <div className="flex-grow bg-white mx-6 mt-6 rounded-md">
                    {getFileExtension(ftp) === "pdf" ? (
                      <PDFThumbnail fileUrl={ftp} pageIndex={1} />
                    ) : getFileExtension(ftp) === "png" ||
                      getFileExtension(ftp) === "jpg" ? (
                      <ImageThumbnail path={ftp} />
                    ) : getFileExtension(ftp) === "mp4" ? (
                      <div className="flex flex-col justify-center flex-grow">
                        <VideoThumbnails videoUrl={ftp} />
                      </div>
                    ) : (
                      <TextThumbnail path={ftp} />
                    )}
                  </div>

                  <div className="bg-white h-16 py-4 px-4">
                    <p className="flex gap-2 items-center">
                      <span>{getIconColor(ftp)}</span>
                      <span>{getFileName(ftp)}</span>
                    </p>
                  </div>
                </div>
              );
            }
          )}
      </div>
    </div>
  );
}
// **************
type FolderOptionProp = {
  item: string;
  index: number;
  currOpen: number | null;
  getFolder: React.Dispatch<React.SetStateAction<number | null>>;
  setCurrOpen: React.Dispatch<React.SetStateAction<number | null>>;
  id: number;
  onFetch: (args?: any) => void;
  getOpenedFolder: React.Dispatch<React.SetStateAction<number | null>>;
};
const FolderOption = ({
  item,
  index,
  onFetch,
  currOpen,
  setCurrOpen,
  getFolder,
  getOpenedFolder,
  id,
}: FolderOptionProp) => {
  const getFileData = (id: number) => {
    getOpenedFolder(id);
  };
  const isOpen = index === currOpen;
  const handleToggle = () => {
    setCurrOpen(isOpen ? null : index);
  };
  const innerRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (innerRef.current && !innerRef.current.contains(event.target as Node)) {
      setCurrOpen(null); // Reset toggle when clicking outside
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOpenFolder = (id: number) => {
    onFetch({ client: 1, id });
    setCurrOpen(id);
    getFileData(id);
  };
  return (
    <div className="w-64 flex justify-between px-4 items-center py-3 border rounded-md border-opacity-50">
      <div
        className="flex gap-3 items-center hover:cursor-pointer"
        onClick={() => handleOpenFolder(id)}
      >
        <span className="text-xl text-[#EFB034FF]">
          <FaFolder />
        </span>
        <span>{item}</span>
      </div>
      <span
        className="text-xl relative hover:cursor-pointer"
        onClick={handleToggle}
        ref={innerRef}
      >
        <HiOutlineDotsVertical />

        {isOpen && (
          <ul className=" bg-gray-100 rounded-md absolute flex flex-col gap-2 text-gray-400 z-10 right-2 text-sm">
            <li className="hover:bg-gray-500 px-8 py-2 hover:text-white">
              Download
            </li>
            <li className="hover:bg-gray-500 px-8 py-2 hover:text-white">
              Rename
            </li>
            <li className="hover:bg-gray-500 px-8 py-2 hover:text-white">
              Delete
            </li>
          </ul>
        )}
      </span>
    </div>
  );
};
