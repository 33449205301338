import React from "react";
import { EditFilled, ProfileOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Descriptions,
  DescriptionsProps,
  Space,
  Typography,
} from "antd";
import { useEmployeeEducationalBackgroundQuery } from "../../store";
import { format } from "date-fns";

interface EmployeeInformationApi {
  key: string;
  label: string;
  value?: string;
  description?: string[];
}

// text-black
const ViewprofileEmployeeInformation = (data: any) => {
  const { Text, Paragraph } = Typography;

  // expected response from api
  //   {
  //     id: 1,
  //     label: Employee Id,
  //     vaue: 101
  //   }
  console.log(data.data.data);
  const id = data.data.data.id;
  const {
    data: empEduc,
    isLoading,
    isSuccess,
    isError,
  } = useEmployeeEducationalBackgroundQuery(id);

  let educBG = [];
  if (isLoading) {
    console.log("loading...");
  } else {
    console.log(empEduc);
    educBG = empEduc.data.map((item: any, index: number) => ({
      key: index + 1,
      label: ["Primary", "Secondary", "Tertiary"][item.educ_type - 1],
      description: [
        `${item.school_name}`,
        `${format(item.date_graduated, "MMM dd, yyyy")}`,
      ],
    }));
  }
  const personalInformations: EmployeeInformationApi[] = [
    {
      key: "1",
      label: " Employee Id",
      value: data.data.data.employee_id,
    },
    {
      key: "2",
      label: "Birthday",
      value: format(data.data.data.birthday, "MMM dd, yyyy"),
    },
    {
      key: "3",
      label: "Gender",
      value: ["Male", "Female", "Other"][data.data.data.gender - 1],
    },
    {
      key: "4",
      label: "Age",
      value: data.data.data.age,
    },
    {
      key: "5",
      label: "Phone",
      value: data.data.data.phone_number ?? data.data.data.mobile_number,
    },
    {
      key: "6",
      label: "Marital Status",
      value: ["Single", "Married"][data.data.data.martial_status - 1],
    },
    {
      key: "7",
      label: "Email",
      value: data.data.data.user.email,
    },
    {
      key: "8",
      label: "Nationality",
      value: data.data.data.civil_status,
    },
  ];

  const addressInformations: EmployeeInformationApi[] = [
    {
      key: "1",
      label: "Region",
      value: data.data.data.address_1?.split(",")[0] ?? "",
    },
    {
      key: "2",
      label: "Provice",
      value: data.data.data.address_1?.split(",")[1] ?? "",
    },
    {
      key: "3",
      label: "Municipality/City",
      value: data.data.data.address_1?.split(",")[2] ?? "",
    },
    {
      key: "4",
      label: "Street Information",
      value: data.data.data.address_1?.split(",")[3] ?? "",
    },
  ];

  const educationInformations: EmployeeInformationApi[] = educBG;
  // {
  //   key: "1",
  //   label: "Primary",
  //   description: ["Kabanding Elementary School", "09-10-1990"],
  // },
  // {
  //   key: "2",
  //   label: "Secondary",
  //   description: ["Kabakalan National HighSchool", "09-10-1950"],
  // },
  // {
  //   key: "1",
  //   label: "Tertiary",
  //   description: ["Lolo State University", "09-10-1980"],
  // },

  const contactInformations: EmployeeInformationApi[] = [
    {
      key: "1",
      label: "Name",
      value: data.data.data.cpioe,
    },
    {
      key: "2",
      label: "Number",
      value: data.data.data.cpioe_contact_no,
    },
    {
      key: "3",
      label: "Relationship",
      value: [
        "Spouse",
        "Parent",
        "Child",
        "Sibling",
        "Grandparent",
        "Grandchild",
      ][data.data.data.cpioe_relation - 1],
    },
  ];

  const governmentIdInformations: EmployeeInformationApi[] = [
    {
      key: "1",
      label: "SSS No.",
      value: data.data.data.sss_no,
    },
    {
      key: "2",
      label: "Pagibig HDMF No.",
      value: data.data.data.pagibig_no,
    },
    {
      key: "3",
      label: "Philhealth No.",
      value: data.data.data.phil_no,
    },
    {
      key: "4",
      label: "Tin No.",
      value: data.data.data.tin_no,
    },
  ];

  // function for rendering the description labels and value
  const returnEmployeeInformation = (
    arr: EmployeeInformationApi[]
  ): DescriptionsProps["items"] => {
    return arr.map((entry) => ({
      key: entry.key,
      label: (
        <Paragraph className=" font-sans text-neutral-500">
          {entry.label}
        </Paragraph>
      ),
      children: entry.value ? (
        <Paragraph className=" font-sans text-neutral-500">
          {entry.value}
        </Paragraph>
      ) : (
        <div>
          {entry.description && entry.description.length > 1 ? (
            <>
              <Text className="font-sans">{entry.description[0]}</Text>
              <Paragraph className="font-sans">
                {entry.description[1]}
              </Paragraph>
            </>
          ) : (
            ""
          )}
        </div>
      ),
    }));
  };

  const personalInformation = returnEmployeeInformation(personalInformations);
  const addressInformation = returnEmployeeInformation(addressInformations);
  const educationInformation = returnEmployeeInformation(educationInformations);
  const contactInformation = returnEmployeeInformation(contactInformations);
  const governmentIdInformation = returnEmployeeInformation(
    governmentIdInformations
  );

  return (
    <>
      <Card
        title={
          <Space direction="horizontal" className="text-xl space-x-3 text">
            <ProfileOutlined className="text-[#0056D2] text-2xl" />
            <Text className="font-sans text-xl text-neutral-600">
              General Information
            </Text>
          </Space>
        }
        className=" mt-10"
      >
        <Space direction="vertical" className=" space-y-3">
          <Descriptions
            title={
              <p className="font-sans text-lg text-black font-semibold">
                Personal Information
              </p>
            }
            extra={
              <Button
                type="text"
                color="green"
                icon={<EditFilled className="text-[#0056D2]" />}
                className="text-[#0056D2]"
              >
                Edit
              </Button>
            }
            items={personalInformation}
            column={2}
            className=" border-b border-neutral-300 pb-5"
          />
          <Descriptions
            title={
              <p className="font-sans text-lg text-black font-semibold">
                Address Information
              </p>
            }
            extra={
              <Button
                type="text"
                color="green"
                icon={<EditFilled className="text-[#0056D2]" />}
                className="text-[#0056D2]"
              >
                Edit
              </Button>
            }
            items={addressInformation}
            column={2}
            className=" border-b border-neutral-300 pb-5"
          />
          <Descriptions
            title={
              <p className="font-sans text-lg text-black font-semibold">
                Education
              </p>
            }
            extra={
              <Button
                type="text"
                color="green"
                icon={<EditFilled className="text-[#0056D2]" />}
                className="text-[#0056D2]"
              >
                Edit
              </Button>
            }
            items={educationInformation}
            column={2}
            className=" border-b border-neutral-300 pb-5"
          />
          <Descriptions
            title={
              <p className="font-sans text-lg text-black font-semibold">
                Contact Person In Case Of Emergency
              </p>
            }
            extra={
              <Button
                type="text"
                color="green"
                icon={<EditFilled className="text-[#0056D2]" />}
                className="text-[#0056D2]"
              >
                Edit
              </Button>
            }
            items={contactInformation}
            column={2}
            className=" border-b border-neutral-300 pb-5"
          />
          <Descriptions
            title={
              <p className="font-sans text-lg text-black font-semibold">
                Government Id Numbers
              </p>
            }
            extra={
              <Button
                type="text"
                color="green"
                icon={<EditFilled className="text-[#0056D2]" />}
                className="text-[#0056D2]"
              >
                Edit
              </Button>
            }
            items={governmentIdInformation}
            column={2}
            className=" border-b border-neutral-300 pb-5"
          />
        </Space>
      </Card>
    </>
  );
};

export default ViewprofileEmployeeInformation;
