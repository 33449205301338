import React from "react";

type CityMapProps = {
    location: string;
};

const getGoogleMapsEmbedUrl = (location: string) => {
    const encodedLocation = encodeURIComponent(location);
    return `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.104928554607!2d-122.42040648468092!3d37.77492927975902!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085809c3e508e2d%3A0xf7f43f5b9293d2a5!2s${encodedLocation}!5e0!3m2!1sen!2sph!4v1618935288348!5m2!1sen!2sph`;
};

const GoogleMapIframe: React.FC<CityMapProps> = ({ location }) => {
    return (
        <iframe
            title="Google Maps"
            src={getGoogleMapsEmbedUrl(location)}
            className="w-full h-64 md:h-full rounded-lg"
            loading="lazy"
        ></iframe>
    );
};

export default GoogleMapIframe;
