import React, { useState } from "react";
import Select from "react-select";

type SearchableDropdownProps = {
    label: string;
    options: { value: string; label: string }[];
    defaultValue?: { value: string; label: string };
    onChange?: (selected: { value: string; label: string } | null) => void;
};

const SearchDropdown: React.FC<SearchableDropdownProps> = ({
    label,
    options,
    defaultValue,
    onChange,
}) => {
    const [selectedOption, setSelectedOption] = useState(defaultValue || null);

    const handleChange = (selected: { value: string; label: string } | null) => {
        setSelectedOption(selected);
        if (onChange) {
            onChange(selected);
        }
    };

    return (
        <div>
            <label className="block text-gray-700 font-medium">{label}</label>
            <Select
                options={options}
                value={selectedOption}
                onChange={handleChange}
                placeholder="Select an option"
                className="mt-1"
                styles={{
                    control: (base) => ({
                        ...base,
                        borderColor: "rgb(209 213 219)", // Tailwind's 'gray-300'
                        backgroundColor: "rgb(243 244 246)", // Tailwind's 'gray-100'
                        borderRadius: "0.5rem", // Tailwind's 'rounded-lg'
                        padding: "0.25rem", // Tailwind's 'px-3 py-2'
                        boxShadow: "none",
                        "&:hover": {
                            borderColor: "rgb(59 130 246)", // Tailwind's 'blue-500'
                        },
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: "rgb(107 114 128)", // Tailwind's 'gray-700'
                    }),
                }}
            />
        </div>
    );
};

export default SearchDropdown;
