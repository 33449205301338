import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

interface DateTimeRangePickerProps {
  startLabel?: string;
  endLabel?: string;
  defaultStartDateTime?: string;
  defaultEndDateTime?: string;
  onStartDateTimeChange?: (value: string) => void;
  onEndDateTimeChange?: (value: string) => void;
}

const DateTimeRangePicker: React.FC<DateTimeRangePickerProps> = ({
  startLabel = "Start Date & Time",
  endLabel = "End Date & Time",
  defaultStartDateTime = "",
  defaultEndDateTime = "",
  onStartDateTimeChange,
  onEndDateTimeChange,
}) => {
  // State for start and end date-times
  const [startDateTime, setStartDateTime] = useState<string>(defaultStartDateTime);
  const [endDateTime, setEndDateTime] = useState<string>(defaultEndDateTime);

  // Handlers for changes
  const handleStartDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setStartDateTime(value);
    onStartDateTimeChange?.(value); // Call callback if provided
  };

  const handleEndDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEndDateTime(value);
    onEndDateTimeChange?.(value); // Call callback if provided
  };

  return (
    <Box display="flex" gap={2} flexDirection={{ xs: "column", sm: "row" }}>
      <TextField
        label={startLabel}
        type="datetime-local"
        value={startDateTime}
        onChange={handleStartDateTimeChange}
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      <TextField
        label={endLabel}
        type="datetime-local"
        value={endDateTime}
        onChange={handleEndDateTimeChange}
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
    </Box>
  );
};

export default DateTimeRangePicker;
