import React, { useState } from 'react';
import { TextField, Popper, Paper, Button } from '@mui/material';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';

interface DateRangeInputProps {
  onDateRangeChange?: (range: { from: Date | undefined; to: Date | undefined }) => void;
  initialRange?: { from: Date | undefined; to: Date | undefined };
}

export default function DateRangeInput({ onDateRangeChange, initialRange }: DateRangeInputProps) {
  const [selectedRange, setSelectedRange] = useState<{ from: Date | undefined; to: Date | undefined }>({
    from: undefined,
    to: undefined,
  });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleDateChange = (date: Date) => {
    if (selectedRange.from && !selectedRange.to) {
      setSelectedRange({ from: selectedRange.from, to: date });
    } else {
      setSelectedRange({ from: date, to: undefined });
    }
  };

  const handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleApply = () => {
    console.log('Date Range Applied:', selectedRange);
    setAnchorEl(null);
  };

  const handleInputBlur = () => {};

  return (
    <div>
      <h3>Select a date range:</h3>

      {/* TextField as input */}
      <TextField
        label="Date Range"
        variant="outlined"
        value={
          selectedRange.from && selectedRange.to
            ? `${selectedRange.from.toLocaleDateString()} - ${selectedRange.to.toLocaleDateString()}`
            : ''
        }
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        fullWidth
        sx={{
          zIndex: 10,
        }}
      />

      {/* Popper to show the calendar popup */}
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal={false}
        sx={{
          zIndex: 1500,
        }}
      >
        <Paper elevation={3}>
          <DayPicker
            selected={selectedRange}
            onDayClick={handleDateChange}
            mode="range"
          />
          {/* Apply button below the calendar */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleApply}
            sx={{ marginTop: '10px' }}
          >
            Apply
          </Button>
        </Paper>
      </Popper>

      <div>
        {selectedRange.from && selectedRange.to ? (
          <p>
            Selected range: {selectedRange.from.toLocaleDateString()} - {selectedRange.to.toLocaleDateString()}
          </p>
        ) : (
          <p>Please select a start and end date.</p>
        )}
      </div>
    </div>
  );
}
