import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const BarChart: React.FC = () => {
  const data = {
    labels: ["HR", "Sales", "Marketing", "CI", "Operations"], // Departments
    datasets: [
      {
        label: "Achieved",
        data: [170, 65, 80, 70, 130], // Current progress
        backgroundColor: "#5B8FF9", // Progress bar color
      },
      {
        label: "Remaining",
        data: [30, 135, 120, 130, 70], // Remaining to goal (calculated as Goal - Achieved)
        backgroundColor: "#E0E0E0", // Gray for remaining part
      },
    ],
  };

  const options: import("chart.js").ChartOptions<"bar"> = {
    responsive: true,
    indexAxis: "y", // Make the bars horizontal
    plugins: {
      legend: {
        position: "top", // Position of the legend
      },
      tooltip: {
        enabled: true, // Enable tooltips
      },
    },
    scales: {
      x: {
        beginAtZero: true, // Start X-axis at zero
        max: 200, // Set max value based on the goal
        ticks: {
          stepSize: 50, // Define step size
        },
      },
      y: {
        stacked: true, // Stacked bars for horizontal axis
      },
    },
  };

  return (
    <div style={{ width: "500px", height: "500px" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
