import React, { useState } from "react";
import {
  Button,
  ConfigProvider,
  Form,
  Modal,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import { FileAddOutlined, InboxOutlined } from "@ant-design/icons";
import { useAddNewDocumentFilesMutation } from "../../../../../store";
import { CoreCustomToast } from "../../../../../utils/CoreCustomToast";

type UploadFileProps = {
  type?: number;
  clientId: number;
  userId?: number;
  fileOpen: number | null;
};
const AddNewFile: React.FC<UploadFileProps> = ({ clientId, fileOpen }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState<any[]>([]);
  const [addDocument] = useAddNewDocumentFilesMutation();

  // function for toggle modal
  const toggleModal = () => {
    isModalOpen ? setIsModalOpen(false) : setIsModalOpen(true);
  };
  // ant design props for typescript and obj also function
  const props: UploadProps = {
    name: "file",
    multiple: true,
    beforeUpload: () => false,
    showUploadList: true,
    onChange(info) {
      setFile(info.fileList.map((file) => file.originFileObj as File));
    },
    onDrop(e) {
      setFile(Array.from(e.dataTransfer.files));
    },
  };

  // handle submit of data to api
  const handleSubmit = () => {
    const data = {
      link: "www.placeholder.com",
      version: 1, //default
      // control_number:
    };
    const formData = new FormData();
    file.forEach((file) => {
      formData.append("ftp", file); // 'files' is the key name expected by the server
    });

    for (const key in data) {
      const value = data[key as keyof typeof data];
      if (value !== undefined && value !== null) {
        formData.append(key, value as string);
      }
    }
    async function submitData(data: FormData) {
      const addDocs = await addDocument({
        client: clientId,
        id: fileOpen,
        data,
      }).unwrap();
      console.log(addDocs);

      CoreCustomToast(addDocs.success, addDocs.message);

      if (addDocs.success === true) {
        console.log("success");
        setFile([""]);
        setIsModalOpen(false);
      } else if (addDocs.success === false) {
        console.log("error");
      } else {
        console.log("still error");
      }
    }

    submitData(formData);
  };

  return (
    <>
      {/* cfd413 */}
      <ConfigProvider theme={{ token: { colorPrimary: "#ea580c" } }}>
        <Button type="primary" onClick={toggleModal} icon={<FileAddOutlined />}>
          <Typography.Text className=" font-sans text-slate-100">
            Add New
          </Typography.Text>
        </Button>
      </ConfigProvider>

      <Modal
        title={
          <Typography.Paragraph className=" font-sans text-xl mx-1">
            Add Documents
          </Typography.Paragraph>
        }
        open={isModalOpen}
        okText="Submit"
        onOk={handleSubmit}
        cancelText="Close"
        onCancel={toggleModal}
      >
        <Form layout="vertical">
          <Form.Item
            label={
              <Typography.Text className=" font-sans font-medium">
                Files
              </Typography.Text>
            }
          >
            <Upload.Dragger {...props} listType="picture">
              <InboxOutlined className=" text-6xl text-[#cfd413]" />
              <Typography.Text className="block text-base font-sans">
                Click or drag file to this area to upload
              </Typography.Text>
              <Typography.Text className="block text-sm text-slate-500 font-sans">
                Upload your file by clicking here or dragging it into this area.
              </Typography.Text>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddNewFile;
