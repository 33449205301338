import React from 'react';
import {toast } from 'react-toastify';
import crypto from 'crypto';


// Reusable function
const api_response = (type: boolean, message: string) => {
    console.log(type)
    if (type === true){
        toast.success(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        })
    }
    else {
        toast.error(message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        })
    }
}


export default {api_response};
