import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");
const employee_detailed = JSON.parse(
  localStorage.getItem("account_detail") || "{}"
);
const clientFromStorage: any = localStorage.getItem("employee_detail");
const clientObj = JSON.parse(clientFromStorage);
const client = clientObj?.client?.id;

const employeeApi = createApi({
  reducerPath: "employee",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  tagTypes: ["employee"],
  endpoints(builder) {
    return {
      addEmployee: builder.mutation({
        query: (form) => {
          return {
            url: "/api/employee/add_employee/",
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      fetchEmployee: builder.query({
        query: ({ page, pageSize, management = false }) => {
          return {
            url: "/api/employee/employee_list/",
            headers: { Authorization: "token " + token },
            params: {
              client_id: client,
              page,
              page_size: pageSize,
              management,
            },
            method: "GET",
          };
        },
        providesTags: ["employee"],
      }),
      employeeDetailed: builder.query({
        query: (id) => {
          console.log(id);
          return {
            url: `/api/employee/${id}/employee_detailed/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      employeeDetailedEdit: builder.mutation({
        query: (form) => {
          console.log(form);
          return {
            url: `/api/employee/${employee_detailed.id}/employee_detail_edit/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      employeebadges: builder.query({
        query: (id) => {
          return {
            url: `/api/employee/${id}/employee_badge/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      employeeAssignedAssessment: builder.mutation({
        query: (form) => {
          return {
            url: `/api/employee/employee_assign_assessment/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      employeeAttendance: builder.mutation({
        query: (form) => {
          console.log(form);
          return {
            url: `/api/employee/save_attendance/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      EmployeeAttendance: builder.query({
        query: () => {
          return {
            url: `/api/employee/${employee_detailed.id}/get_attendances/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      employeeEducationalBackground: builder.query({
        query: (id) => {
          return {
            url: `/api/employee/get_educ_bg/`,
            headers: { Authorization: "token " + token },
            method: "GET",
            params: { id },
          };
        },
      }),
      employeeDepartment: builder.query({
        query: (id) => {
          return {
            url: `/api/employee/get_dept_emp/`,
            headers: { Authorization: "token " + token },
            method: "GET",
            params: { client_id: client, department: id },
          };
        },
      }),
      employeeTeamMembers: builder.query({
        query: ({ id, page, pageSize, tm }) => {
          return {
            url: `/api/employee/get_dept_emp/`,
            headers: { Authorization: "token " + token },
            method: "GET",
            params: {
              client_id: client,
              department: id,
              page,
              page_size: pageSize,
              tm,
            },
          };
        },
      }),
    };
  },
});
export const {
  useLazyFetchEmployeeQuery,
  useAddEmployeeMutation,
  useEmployeeDetailedQuery,
  useEmployeeDetailedEditMutation,
  useEmployeebadgesQuery,
  useEmployeeAssignedAssessmentMutation,
  useEmployeeAttendanceMutation,
  useEmployeeAttendanceQuery,
  useEmployeeEducationalBackgroundQuery,
  useEmployeeDepartmentQuery,
  useEmployeeTeamMembersQuery,
} = employeeApi;
export { employeeApi };
