import React, { useState } from 'react';
import {
  Dialog, DialogContent, DialogActions,
  TextField, Button, IconButton
} from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { Typography } from "antd";
import { FileAddOutlined } from "@ant-design/icons";
import RichText from '../../inputs/RichText';
import DateRangeInput from '../../inputs/DateRangeInput';
import { useAddMemoMutation, useFetchDepartmentQuery } from '../../../store';
import utils from '../../../utils/core';
import { Employee } from '../../../utils/type';
import moment from "moment";

const AddMemo = () => {
  const savedEmployee: Employee = JSON.parse(
    localStorage.getItem("employee_detail") || '{}'
  );


  const [open, setOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [coverPhoto, setCoverPhoto] = useState<File | null>(null);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('Voluptate velit nulla cillum duis commodo excepteur esse laboris dolor esse. Tempor dolore aliqua eu elit ad laboris incididunt et');
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [range, setRange] = useState<{ from: Date | undefined; to: Date | undefined }>({
    from: new Date(),
    to: undefined,
  });

  // New loading state
  const [loading, setLoading] = useState<boolean>(false);

  // API Call
  const [save_memo] = useAddMemoMutation();

  const handleEditorChange = (newText: string) => {
    setDescription(newText);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setImagePreview(null);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setImagePreview(imageURL);
      setCoverPhoto(file);
    }
  };

  const handleDepartmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDepartment(event.target.value);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDateRangeChange = (newRange: { from: Date | undefined; to: Date | undefined }) => {
    setRange(newRange);
  };

  // Api function call
  const handlePost = async () => {
    // Set loading state to true
    setLoading(true);

    let formData = new FormData();
    formData.append("client_id", savedEmployee.client.id.toString());
    if (coverPhoto) {
      formData.append("cover_photo", coverPhoto);
    }
    formData.append("description", description);
    formData.append(
      "date_uploaded",
      moment(new Date()).format("YYYY-MM-DD")
    );
    formData.append(
      "start",
      moment(range.from).format("YYYY-MM-DD")
    );
    formData.append(
      "end",
      moment(range.to).format("YYYY-MM-DD")
    );
    formData.append("title", title);
    formData.append(
      "department", String(selectedDepartment)
    );
    formData.append(
      "user_id",
      savedEmployee.user.id.toString()
    );

    try {
      const addM = await save_memo(formData).unwrap();
      utils.api_response(addM.success, addM.message);
      handleClose();
    } catch (error) {
      console.error("Error posting memo:", error);
    } finally {
      // Reset loading state
      setLoading(false);
    }
  };

  const departmentApi = useFetchDepartmentQuery("");
  // end of api function call

  return (
    <div>
      <Button variant="contained" onClick={handleOpen} color="warning">
        <FileAddOutlined className="mr-2" />
        <Typography.Text className="font-sans text-slate-100">
          Add New
        </Typography.Text>
      </Button>

      <Dialog open={open} onClose={handleClose} fullScreen>
        <DialogContent dividers className="flex items-center justify-center">
          <div className="w-[850px]">
            <div className="flex items-center space-x-5 mt-8">
              <img src="/images/paperPlus.png" alt="Paper Plus" />
              <Typography>Add Memo</Typography>
            </div>
            <div className="bg-gray-100 h-60 flex items-center justify-center rounded-lg mb-6 relative">
              {imagePreview ? (
                <img src={imagePreview} alt="Cover Preview" className="h-60 w-auto object-contain rounded-lg" />
              ) : (
                <span className="text-gray-400 text-lg">Add Cover Photo</span>
              )}
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                className="absolute bottom-2 right-2"
              >
                <input hidden accept="image/*" type="file" onChange={handleImageChange} />
                <PhotoCamera />
              </IconButton>
            </div>

            <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-4">
              <TextField
                label="Title"
                variant="outlined"
                className="mb-4"
                onChange={handleTitleChange}
              />
              <DateRangeInput
                onDateRangeChange={handleDateRangeChange}
                initialRange={range}
              />
            </div>

            <div className="mb-4">
              <TextField
                select
                SelectProps={{ native: true }}
                variant="outlined"
                onChange={handleDepartmentChange}
                value={selectedDepartment}
              >
                {departmentApi.isLoading && <option>Loading...</option>}
                {departmentApi.isError && <option>Error loading departments</option>}
                {!departmentApi.isLoading && !departmentApi.isError && <option value="">Select Department</option>}
                {!departmentApi.isLoading &&
                  !departmentApi.isError &&
                  departmentApi.data?.data.map((department: { id: number; name: string }) => (
                    <option key={department.id} value={department.id}>
                      {department.name}
                    </option>
                  ))}
              </TextField>
            </div>

            <div className="mb-4">
              <RichText value={description} onChange={handleEditorChange} />
            </div>

            <div className="flex items-center justify-center py-4">
              <Button
                onClick={handlePost}
                variant="contained"
                color="success"
                disabled={loading}
              >
                {loading ? "Posting..." : "Post"}
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddMemo;