import {
  PlusCircleOutlined,
  UndoOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Checkbox,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Typography,
  Upload,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { InputLabelTypes } from "../Types";
import {
  useLazyFetchBarangayQuery,
  useLazyFetchCityQuery,
  useLazyFetchProvinceQuery,
  useLazyFetchRegionsQuery,
} from "../../store";
import { useMultiStep } from "../../context/StepContext";
import { RcFile } from "antd/es/upload";

type ChildrenDataInput = {
  name: string;
  age: string;
};

type Inputs = {
  gender: number;
  age: string;
  birthday: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  empId: string;
  email: string;
  address_1: string;
  address_2: string;
  cpioe: string;
  cpioe_contanct_no: string;
  cpioe_relationship: string;
  blood_type: string;
  civil_status: string;
  nationality: string;
};

type HandleChangeInput =
  | { target: { name: string; value: string; checked?: boolean } }
  | React.ChangeEvent<HTMLInputElement>
  | RadioChangeEvent
  | string;

const AddEmployeeGeneral = () => {
  const { Text, Paragraph } = Typography;
  // const [input, setInput] = useState({
  //   firstName: "",
  //   lastName: "",
  //   employeeId: "",
  //   phoneNumber: "",
  // });
  const [isTheSame, setIsTheSame] = useState(true);
  const [childrenInput, setChildrenInput] = useState<ChildrenDataInput[]>([]);
  const { empInputs, setEmpInputs } = useMultiStep();
  const [inputs, setInputs] = useState<Inputs>({
    gender: 0,
    age: "",
    birthday: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    empId: "",
    email: "",
    address_1: "",
    address_2: "",
    cpioe: "",
    cpioe_contanct_no: "",
    cpioe_relationship: "",
    blood_type: "",
    civil_status: "",
    nationality: "",
  });
  const [file, setFile] = useState<RcFile | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleBeforeUpload = (file: RcFile) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("You can only upload image files!");
      return Upload.LIST_IGNORE; // Prevents file from being uploaded.
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return Upload.LIST_IGNORE; // Prevents file from being uploaded.
    }

    // Preview the uploaded image
    const reader = new FileReader();
    reader.onload = () => setImageUrl(reader.result as string);
    reader.readAsDataURL(file);
    setEmpInputs((prev) => ({ ...prev, avatar: file }));
    setFile(file); // Save the file in state
    return false; // Prevent automatic upload
  };

  const InputLabel: InputLabelTypes[] = [
    {
      id: 1,
      name: "First Name",
      inputName: "first_name",
      placeHolder: "First Name",
      value: "",
    },
    {
      id: 2,
      name: "Middle Name",
      inputName: "middle_name",
      placeHolder: "Middle Name",
      value: "",
    },
    {
      id: 3,
      name: "Last Name",
      inputName: "last_name",
      placeHolder: "Last Name",
      value: "",
    },
    {
      id: 4,
      name: "Employee Id",
      inputName: "empId",
      placeHolder: "ex: 51234151",
      value: "",
    },
    {
      id: 5,
      name: "Phone Number",
      inputName: "phone_no",
      placeHolder: "",
      value: "",
    },
    {
      id: 6,
      name: "Email",
      inputName: "email",
      placeHolder: "example@email.com",
      value: "",
    },
    {
      id: 7,
      name: "Address Line 1",
      inputName: "address_1",
      value: "",
    },
    {
      id: 8,
      name: "Address Line 2",
      inputName: "address_2",
      value: "",
    },

    {
      id: 9,
      name: "Name",
      inputName: "cpioe",
      value: "",
    },
    {
      id: 10,
      name: "Relationship",
      inputName: "cpioe_relationship",
      value: "",
    },
    {
      id: 11,
      name: "Contact Number",
      inputName: "cpioe_contact_no",
      value: "",
    },
  ];
  const limit = 20;
  const [
    triggerRegion,
    {
      data: fetchRegion,
      isFetching: isFetchingReg,
      isSuccess: isSuccessReg,
      isError: isErrorReg,
    },
  ] = useLazyFetchRegionsQuery();

  const [
    triggerProv,
    {
      data: fetchProvince,
      isFetching: isFetchingProv,
      isSuccess: isSuccessProv,
      isError: isErrorProv,
    },
  ] = useLazyFetchProvinceQuery();

  const [
    triggerCity,
    {
      data: fetchCity,
      isFetching: isFetchingCity,
      isSuccess: isSuccessCity,
      isError: isErrorCity,
    },
  ] = useLazyFetchCityQuery();

  const [
    triggerBrgy,
    {
      data: fetchBrgy,
      isFetching: isFetchingBrgy,
      isSuccess: isSuccessBrgy,
      isError: isErrorBrgy,
    },
  ] = useLazyFetchBarangayQuery();

  useEffect(() => {
    triggerRegion({ search: "", limit });
    console.log(fetchRegion);
  }, [triggerRegion]);

  const handleChangeInput = (e: HandleChangeInput) => {
    if (typeof e === "string") {
      setEmpInputs((prev) => ({
        ...prev,
        [e]: e,
      }));
    } else {
      const { name, value, checked } = e.target;

      setEmpInputs((prev) => ({
        ...prev,
        [name as string]: value,
      }));
    }
  };

  const handleSelectChange = (
    value: number | null,
    index: number,
    label: string
  ) => {
    if (index === 1) {
      setEmpInputs((prev) => ({
        ...prev,
        paRegion: value,
        paRegionLabel: label,
        address_1: label,
      }));
      triggerRegion({ search: "" });
      if (isSuccessReg) triggerProv({ region: value });
    } else if (index === 2) {
      setEmpInputs((prev) => ({
        ...prev,
        paProvince: value,
        paProvinceLabel: label,
        address_1: `${empInputs.address_1}, ${label}`,
      }));
      if (isSuccessProv) triggerCity({ province: value });
    } else if (index === 3) {
      if (isSuccessCity) triggerBrgy({ city: value });
      setEmpInputs((prev) => ({
        ...prev,
        paCity: value,
        paCityLabel: label,
        address_1: `${empInputs.address_1}, ${label}`,
      }));
    } else if (index === 4) {
      setEmpInputs((prev) => ({
        ...prev,
        paBrgy: value,
        paBrgyLabel: label,
        address_1: `${empInputs.address_1}, ${label}`,
      }));
    }
  };
  const arrayOne = InputLabel.filter((_, index) => index <= 5);

  const addRow = () => {
    setChildrenInput([...childrenInput, { name: "", age: "" }]);
  };
  return (
    <>
      <Space direction="vertical" className="block mt-10 mb-8 space-y-5 mx-16">
        <Text className=" font-sans text-2xl font-bold text-[#0056D2]">
          Personal Information
        </Text>
        <div className="grid grid-flow-row grid-cols-3 gap-8 mt-5">
          <div className="flex flex-col space-y-3">
            <Text className=" font-sans">Gender</Text>
            <Radio.Group
              name="gender"
              value={empInputs.gender}
              onChange={handleChangeInput}
            >
              <Radio value={1} className=" font-sans">
                Male
              </Radio>
              <Radio value={2} className=" font-sans">
                Female
              </Radio>
            </Radio.Group>
          </div>
          <div className="flex flex-col">
            <Text className="font-sans">Age</Text>
            <Input
              name="age"
              style={{ width: 200 }}
              value={empInputs.age}
              onChange={handleChangeInput}
              className="custom-style-input mt-2"
            />
          </div>
          <div className="flex flex-col">
            <Text className="font-sans">Birthday</Text>
            <Input
              placeholder="DD/MM/YYYY"
              value={empInputs.birthday}
              onChange={handleChangeInput}
              name="birthday"
              type="date"
              style={{ width: 200 }}
              className="custom-style-input mt-2"
            />
          </div>
          {arrayOne.map((entry) => (
            <div key={entry.id}>
              <Text className="font-sans">{entry.name}</Text>
              <Input
                placeholder={entry.placeHolder || ""}
                name={entry.inputName}
                className="custom-style-input mt-2"
                value={empInputs[entry.inputName as keyof Inputs]}
                onChange={handleChangeInput}
              />
            </div>
          ))}
          <div className="flex flex-col">
            <Text className="font-sans">Blood Type</Text>
            <Select
              placeholder=" Select Blood type"
              style={{ width: 230 }}
              className=" font-sans text-sm mt-2"
              options={[
                { value: "A+", label: "A+" },
                { value: "A-", label: "A-" },
                { value: "B+", label: "B+" },
                { value: "B-", label: "B-" },
                { value: "AB+", label: "AB+" },
                { value: "AB-", label: "AB-" },
                { value: "O+", label: "O+" },
                { value: "O-", label: "O-" },
              ]}
              value={empInputs.blood_type}
              onChange={(value) =>
                handleChangeInput({ target: { name: "blood_type", value } })
              }
            />
          </div>
          <div className="flex flex-col">
            <Text className="font-sans">Civil Status</Text>
            <Select
              placeholder=" Select Civil Status"
              style={{ width: 230 }}
              className=" font-sans text-sm mt-2"
              options={[
                { value: "Single", label: "Single" },
                { value: "Married", label: "Married" },
                { value: "Divorced", label: "Divorced" },
                { value: "Widowed", label: "Widowed" },
                { value: "Separated", label: "Separated" },
                { value: "Annulled", label: "Annulled" },
              ]}
              value={empInputs.civil_status}
              onChange={(value) =>
                handleChangeInput({ target: { name: "civil_status", value } })
              }
            />
          </div>
          <div>
            <Text className="font-sans">Id Image</Text>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={handleBeforeUpload}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <div>
                  <UploadOutlined className="text-xl" />
                  <div>Upload</div>
                </div>
              )}
            </Upload>
          </div>
        </div>
        <div className="flex items-center space-x-3">
          <Text className="font-sans text-neutral-500">
            Do you have children?
          </Text>
          <PlusCircleOutlined
            onClick={addRow}
            className=" text-xl cursor-pointer text-[#68b046] hover:text-[#46842a]"
          />
          <UndoOutlined
            onClick={() => setChildrenInput([])}
            className=" text-xl cursor-pointer text-neutral-500 hover:text-neutral-800"
          />
        </div>
        {childrenInput.map((entry, index) => (
          <div className=" grid grid-cols-2 gap-5" key={index}>
            <div>
              <Text className="font-sans">Full name</Text>
              <Input name={entry.name} className="custom-style-input mt-2" />
            </div>
            <div style={{ width: 230 }}>
              <Text className="font-sans">Age</Text>
              <Input
                name={entry.age}
                className="custom-style-input mt-2 pr-28"
              />
            </div>
          </div>
        ))}
        <Space className="block mt-10 mb-5 space-y-5">
          <Text className=" font-sans text-2xl font-bold text-[#0056D2]">
            Contact Person In Case Of Emergency
          </Text>
          <div className="grid grid-flow-row grid-cols-3 gap-8 mt-8">
            <div>
              <Text className="font-sans">Name</Text>
              <Input
                name="cpioe"
                value={empInputs.cpioe}
                placeholder="Name"
                onChange={handleChangeInput}
                className="custom-style-input mt-2"
              />
            </div>
            <div className="flex flex-col">
              <Text className="font-sans">Relationship</Text>
              <Select
                placeholder=" Select Relationship Status"
                style={{ width: 230 }}
                className=" font-sans text-sm mt-2"
                options={[
                  { value: 1, label: "Spouse" },
                  { value: 2, label: "Parent" },
                  { value: 3, label: "Child" },
                  { value: 4, label: "Sibling" },
                  { value: 5, label: "Grandparent" },
                  { value: 6, label: "Grandchild" },
                ]}
                value={empInputs.cpioe_relationship}
                onChange={(value) =>
                  handleChangeInput({
                    target: { name: "cpioe_relationship", value },
                  })
                }
              />
            </div>
            <div>
              <Text className="font-sans">Contact Number</Text>
              <Input
                name="cpioe_contanct_no"
                value={empInputs.cpioe_contanct_no}
                placeholder="ex: 098512341"
                onChange={handleChangeInput}
                className="custom-style-input mt-2"
              />
            </div>
          </div>
        </Space>
        <Space className="block mt-10 mb-5 space-y-5">
          <Text className=" font-sans text-2xl font-bold text-[#0056D2]">
            Permanent Address
          </Text>
          <div className="grid grid-cols-3 grid-flow-row gap-5 items-center">
            <Space direction="vertical">
              <Text className="font-sans">Region</Text>
              <Select
                placeholder="Select Region"
                className="w-full"
                options={fetchRegion?.data.map((item: any) => ({
                  value: item.id,
                  label: item.name,
                }))}
                value={empInputs.paRegionLabel}
                onChange={(value, option) => {
                  let label = "";
                  if (Array.isArray(option)) {
                    label = String(option[0]?.label || "");
                  } else {
                    label = String(option?.label || "");
                  }
                  handleSelectChange(Number(value), 1, label);
                }}
              ></Select>
            </Space>
            <Space direction="vertical">
              <Text className="font-sans">Province</Text>
              <Select
                disabled={empInputs.paRegion ? false : true}
                placeholder="Select Province"
                className="w-full"
                options={fetchProvince?.data.map((item: any) => ({
                  value: item.id,
                  label: item.name,
                }))}
                value={empInputs.paProvinceLabel}
                onChange={(value, option) => {
                  let label = "";
                  if (Array.isArray(option)) {
                    label = String(option[0]?.label || "");
                  } else {
                    label = String(option?.label || "");
                  }
                  handleSelectChange(Number(value), 2, label);
                }}
              ></Select>
            </Space>
            <Space direction="vertical">
              <Text className="font-sans">Municipality</Text>
              <Select
                disabled={empInputs.paProvince ? false : true}
                placeholder="Select Municipality"
                className="w-full"
                options={fetchCity?.data.map((item: any) => ({
                  value: item.id,
                  label: item.name,
                }))}
                value={empInputs.paCityLabel}
                onChange={(value, option) => {
                  let label = "";
                  if (Array.isArray(option)) {
                    label = String(option[0]?.label || "");
                  } else {
                    label = String(option?.label || "");
                  }
                  handleSelectChange(Number(value), 3, label);
                }}
              ></Select>
            </Space>
            <Space direction="vertical">
              <Text className="font-sans">Barangay</Text>
              <Select
                disabled={empInputs.paCity ? false : true}
                placeholder="Select Barangay"
                className="w-full"
                options={fetchBrgy?.data.map((item: any) => ({
                  value: item.id,
                  label: item.name,
                }))}
                value={empInputs.paBrgyLabel}
                onChange={(value, option) => {
                  let label = "";
                  if (Array.isArray(option)) {
                    label = String(option[0]?.label || "");
                  } else {
                    label = String(option?.label || "");
                  }
                  handleSelectChange(Number(value), 4, label);
                }}
              ></Select>
            </Space>
            <div className="mb-2">
              <Text className="font-sans">Nationality</Text>
              <Input
                placeholder="FILIPINO"
                name="nationality"
                className="custom-style-input mt-2"
                value={empInputs.nationality}
                onChange={handleChangeInput}
              />
            </div>
          </div>
          <Space className="space-x-1 mt-5">
            <Text className="font-sans text-2xl font-bold text-[#0056D2]">
              Current Address
            </Text>
            <div className=" space-x-3">
              <Text className="font-sans text-xs text-neutral-500">
                Same as Permanent Address
              </Text>
              <Checkbox
                onChange={() => setIsTheSame(!isTheSame)}
                defaultChecked={isTheSame}
              />
            </div>
          </Space>
          {!isTheSame && (
            <div className="grid grid-cols-3 grid-flow-row gap-5 items-center">
              <Space direction="vertical">
                <Text className="font-sans">Region</Text>
                <Select
                  placeholder="Select Region"
                  className="w-full"
                  options={fetchRegion?.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  value={empInputs.caRegion}
                  onChange={(value, option) => {
                    let label = "";
                    if (Array.isArray(option)) {
                      label = String(option[0]?.label || "");
                    } else {
                      label = String(option?.label || "");
                    }
                    handleSelectChange(Number(value), 1, label);
                  }}
                ></Select>
              </Space>
              <Space direction="vertical">
                <Text className="font-sans">Province</Text>
                <Select
                  disabled={empInputs.caRegion ? false : true}
                  placeholder="Select Province"
                  className="w-full"
                  options={fetchProvince?.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  value={empInputs.caProvince}
                  onChange={(value, option) => {
                    let label = "";
                    if (Array.isArray(option)) {
                      label = String(option[0]?.label || "");
                    } else {
                      label = String(option?.label || "");
                    }
                    handleSelectChange(Number(value), 2, label);
                  }}
                ></Select>
              </Space>
              <Space direction="vertical">
                <Text className="font-sans">Municipality</Text>
                <Select
                  disabled={empInputs.caProvince ? false : true}
                  placeholder="Select Municipality"
                  className="w-full"
                  options={fetchCity?.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  value={empInputs.caCity}
                  onChange={(value, option) => {
                    let label = "";
                    if (Array.isArray(option)) {
                      label = String(option[0]?.label || "");
                    } else {
                      label = String(option?.label || "");
                    }
                    handleSelectChange(Number(value), 3, label);
                  }}
                ></Select>
              </Space>
              <Space direction="vertical">
                <Text className="font-sans">Barangay</Text>
                <Select
                  disabled={empInputs.caCity ? false : true}
                  placeholder="Select Barangay"
                  className="w-full"
                  options={fetchBrgy?.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                  value={empInputs.caBrgy}
                  onChange={(value, option) => {
                    let label = "";
                    if (Array.isArray(option)) {
                      label = String(option[0]?.label || "");
                    } else {
                      label = String(option?.label || "");
                    }
                    handleSelectChange(Number(value), 4, label);
                  }}
                ></Select>
              </Space>
            </div>
          )}
        </Space>
      </Space>
    </>
  );
};

export default AddEmployeeGeneral;
