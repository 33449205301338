import { Modal, Space, Typography } from "antd";
import React, { useState } from "react";
import { HiChevronDoubleDown } from "react-icons/hi";
import { useEmployeeDepartmentQuery } from "../../store";

interface ViewMoreTeamProps {
  department: string;
  id: number;
  deptId: number;
}

interface CardInfo {
  id: number;
  position: string;
  name: string;
  department: string;
}

const ViewMoreTeam = ({ department, id, deptId }: ViewMoreTeamProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const {
    data: fetchEmpDept,
    isLoading,
    isSuccess,
    isError,
  } = useEmployeeDepartmentQuery(deptId);

  let content: React.ReactNode = <></>;
  if (isLoading) {
    console.log("loading...");
    content = <div>Loading...</div>;
  } else if (isError) {
    console.log("error");
  } else {
    const card = fetchEmpDept.data.map((item: any, index: number) => ({
      id: item.id,
      position: item.job_title?.name ?? "",
      name: `${item.user.first_name} ${item.user.last_name}`,
      dpartment: item.department?.name ?? "",
    }));
    // console.log(card);
    content = (
      <>
        <Space className="flex flex-wrap justify-center mt-8 gap-4">
          {card
            .filter((item: any, index: number) => item.id === id)
            .map(({ id, position, name }: CardInfo) => (
              <Space
                className="flex flex-col items-center justify-center h-40 w-40 my-4"
                key={id}
              >
                <img
                  src="../images/jimmy.png"
                  alt="prof"
                  className="aspect-square object-cover shadow-xl shadow-blue-300 w-28 rounded-full"
                />
                <div className="flex flex-col justify-center items-center">
                  <p className="font-sans text-2xl text-[#0056D2] font-bold leading-tight mt-8 text-center">
                    {name}
                  </p>
                  <p className=" font-sans text-sm leading-tight font-medium text-neutral-600 mt-1">
                    {position}
                  </p>
                </div>
              </Space>
            ))}
        </Space>
        <Space className="flex flex-wrap justify-center mt-12 gap-5">
          {card
            .filter((item: any, index: number) => item.id !== id)
            .map(({ id, position, name }: CardInfo) => (
              <Space
                className="flex flex-col items-center justify-center h-36 w-40 my-6"
                key={id}
              >
                <img
                  src="../images/jimmy.png"
                  alt="prof"
                  className="aspect-square object-cover shadow-xl shadow-blue-300 w-28 rounded-full"
                />
                <div className="flex flex-col justify-center items-center">
                  <p className=" font-sans text-base text-[#0056D2] font-bold leading-tight mt-8 text-center">
                    {name}
                  </p>
                  <p className=" font-sans text-xs leading-tight font-medium text-neutral-600 mt-1 text-center">
                    {position}
                  </p>
                </div>
              </Space>
            ))}
        </Space>
      </>
    );
  }
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { Text } = Typography;

  return (
    <>
      <HiChevronDoubleDown
        onClick={showModal}
        className=" text-[#0056D2] hover:text-[#0058d2c8] text-3xl cursor-pointer"
      />
      <Modal
        title={
          <Text className=" font-sans text-2xl">{department} Department</Text>
        }
        width={800}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        cancelText="Close"
      >
        {content}
      </Modal>
    </>
  );
};

export default ViewMoreTeam;
