import {
  Input,
  Pagination,
  Popover,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import { BsSearch } from "react-icons/bs";
import "../../../App.css";
import {
  BlockOutlined,
  FilterOutlined,
  InsertRowBelowOutlined,
} from "@ant-design/icons";

import { useState } from "react";
import EmployeeMngmntImport from "../../reuseables/EmployeeMngmntImport";
import EmployeeMngmentExport from "../../reuseables/EmployeeMngmentExport";
import EmployeeManagementTableView from "../../table/EmployeeManagementTableView";
import EmployeeMngmentCard from "../../reuseables/EmployeeMngmentCard";
import { ObjType } from "./Type";
import { useEmployeeTeamMembersQuery } from "../../../store";

function TeamMembers() {
  const { Text, Paragraph } = Typography;
  const [toggleView, setToggleView] = useState("CardType");
  const [deptId, setDeptId] = useState(() => {
    const empDetails = localStorage.getItem("employee_detail") || "";
    const empDept = JSON.parse(empDetails).department.id;
    console.log(empDept);
    return empDept ? empDept : 1;
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const changeView = () => {
    toggleView === "ListType"
      ? setToggleView("CardType")
      : setToggleView("ListType");
  };

  const {
    data: fetchEmpDept,
    isLoading,
    isSuccess,
    isError,
    refetch,
  } = useEmployeeTeamMembersQuery({ id: deptId, pageSize, page, tm: true });

  let content: React.ReactNode = <></>;
  if (isLoading) {
    content = <div>Loading...</div>;
  } else if (isError) {
    content = <div>Something went wrong please contact adminitrator</div>;
  } else {
    let card: ObjType[] = fetchEmpDept.data.results.map(
      (item: any, index: number) => ({
        id: item.id,
        position: item.job_title.name,
        name: `${item.user.first_name} ${item.user.last_name}`,
        department: item.department.name,
        status: 1,
      })
    );

    content = (
      <>
        {toggleView === "ListType" && (
          <EmployeeManagementTableView
            count={0}
            dataTables={card}
            page={page}
            setPage={setPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
          />
        )}
        {toggleView === "CardType" && (
          <div className="grid grid-cols-4 grid-flow-row gap-7">
            {card.map(({ position, name, status, department, id }, index) => (
              <EmployeeMngmentCard key={index}>
                <EmployeeMngmentCard.Profile source="../images/jimmy.png" />
                <div className=" flex flex-col justify-center items-center">
                  <EmployeeMngmentCard.Name name={name} id={id} />
                  <EmployeeMngmentCard.Position position={position} />
                  <EmployeeMngmentCard.Department department={department} />
                </div>
                {EmployeeMngmentCard.Social && (
                  <EmployeeMngmentCard.Social viber="#" email="#" />
                )}
              </EmployeeMngmentCard>
            ))}
          </div>
        )}
        <Pagination
          className="flex justify-end mt-4"
          current={page}
          pageSize={pageSize}
          total={fetchEmpDept.data.count}
          onChange={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
            refetch();
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col justify-center items-center mt-10 mb-12 space-y-3">
        <Text className="font-sans text-3xl font-bold">Team Members</Text>
        <Paragraph className=" font-sans text-lg text-neutral-500">
          Hi! These are our team members where combined talents of dedicated
          individuals drive our company's success.
        </Paragraph>
      </div>
      <div className=" flex flex-col my-5 space-y-6">
        <div className=" flex justify-between items-center mt-7 space-x-5 mr-4">
          <div className=" space-x-5 ">
            <Input
              placeholder="Search.."
              className="custom-style-input w-72 bgcy"
              suffix={<BsSearch />}
            />
            <Select
              defaultValue="All position"
              className=" font-sans w-40"
              options={[
                { value: "jack", label: "Jack" },
                { value: "All Office", label: "All Office", disabled: true },
              ]}
            />
            <Select
              defaultValue="All department"
              className=" font-sans w-40"
              options={[
                { value: "jack", label: "Jack" },
                { value: "All Office", label: "All Office", disabled: true },
              ]}
            />
            <Select
              defaultValue="All Status"
              className=" font-sans w-40"
              options={[
                { value: "jack", label: "Jack" },
                {
                  value: "All Status",
                  label: "All Status",
                  disabled: true,
                },
              ]}
            />
          </div>
          <div className=" flex items-center space-x-4">
            <EmployeeMngmntImport />
            <Popover content={<Text className=" font-sans">Clear filter</Text>}>
              <FilterOutlined className="text-base cursor-pointer text-[#0056d2] shadow hover:ring-1 hover:ring-[#0056d2] shadow-neutral-300 rounded-full p-2" />
            </Popover>
            <EmployeeMngmentExport />
            {toggleView === "CardType" && (
              <Popover content={<Text className=" font-sans">List view</Text>}>
                <InsertRowBelowOutlined
                  className="text-base cursor-pointer text-[#0056d2] shadow hover:ring-1 hover:ring-[#0056d2] shadow-neutral-300 rounded-full p-2"
                  onClick={changeView}
                />
              </Popover>
            )}
            {toggleView === "ListType" && (
              <Popover content={<Text className=" font-sans">Card view</Text>}>
                <BlockOutlined
                  className="text-base cursor-pointer text-[#0056d2] shadow hover:ring-1 hover:ring-[#0056d2] shadow-neutral-300 rounded-full p-2"
                  onClick={changeView}
                />
              </Popover>
            )}
          </div>
        </div>
        {content}
      </div>
    </>
  );
}

export default TeamMembers;
