import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Provider } from "react-redux";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");

const addressApi = createApi({
  reducerPath: "Address",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  tagTypes: ["Address"],
  endpoints(builder) {
    return {
      fetchRegions: builder.query({
        query: ({ search, limit }) => {
          return {
            url: `/api/client/address/get_regions/`,
            params: { search: search || "", limit: 100 },
            headers: { Authorization: `token ${token}` },
            method: "GET",
          };
        },
        providesTags: ["Address"],
      }),
      fetchProvince: builder.query({
        query: ({ search, limit, region }) => {
          console.log(region);
          return {
            url: `/api/client/address/get_provinces/`,
            params: { search: search || "", limit: 100, region },
            headers: { Authorization: `token ${token}` },
            method: "GET",
          };
        },
        providesTags: ["Address"],
      }),
      fetchCity: builder.query({
        query: ({ search, limit, province }) => {
          return {
            url: `/api/client/address/get_cities/`,
            params: { search: search || "", limit: 100, province },
            headers: { Authorization: `token ${token}` },
            method: "GET",
          };
        },
        providesTags: ["Address"],
      }),
      fetchBarangay: builder.query({
        query: ({ search, limit, city }) => {
          return {
            url: `/api/client/address/get_barangays/`,
            params: { search: search || "", limit: 100, city },
            headers: { Authorization: `token ${token}` },
            method: "GET",
          };
        },
        providesTags: ["Address"],
      }),
    };
  },
});

export const {
  useLazyFetchRegionsQuery,
  useLazyFetchProvinceQuery,
  useLazyFetchCityQuery,
  useLazyFetchBarangayQuery,
} = addressApi;
export { addressApi };
