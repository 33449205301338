type statusType = {
  id: number;
  label: string;
  color: string;
};
// status for employee profile
export const statusList: statusType[] = [
  {
    id: 1,
    label: "Active",
    color: "text-green-600 font-sans",
  },
  {
    id: 2,
    label: "Suspended",
    color: "text-red-600 fonts-sans",
  },
  {
    id: 3,
    label: "Deleted",
    color: "text-red-600 fonts-sans",
  },
  {
    id: 4,
    label: "Incomplete Profile",
    color: "text-blue-400 font-sans",
  },
  {
    id: 5,
    label: "For Activation",
    color: "text-yellow-400 font-sans",
  },
];
