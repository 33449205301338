import React, { useState, useEffect } from "react";
import { Card, Typography } from "antd";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const { Paragraph } = Typography;
type ImageThumbnailProps = {
  path: string;
};
const TextThumbnail = ({ path }: ImageThumbnailProps) => {
  const [documentContent, setDocumentContent] = useState("");

  useEffect(() => {
    // Fetch the document content (you can replace with actual API or file)
    fetch(`${apiDomain}${path}`)
      .then((response) => response.text())
      .then((text) => setDocumentContent(text));
  }, []);

  return (
    <Card className="h-full">
      <Typography>
        <Paragraph>{documentContent}</Paragraph>
      </Typography>
    </Card>
  );
};

export default TextThumbnail;
