import React, { useState, KeyboardEvent } from "react";

interface Department {
  name: string;
  emails: string[];
}

interface EmailRecipientsProps {
  initialRecipients?: string[];
  sampleEmails: string[];
  departments: Department[];
  onRecipientsChange?: (recipients: string[]) => void;
}

const EmailRecipients: React.FC<EmailRecipientsProps> = ({
  initialRecipients = [],
  sampleEmails,
  departments,
  onRecipientsChange,
}) => {
  const [recipients, setRecipients] = useState<string[]>(initialRecipients);
  const [inputValue, setInputValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const handleAddRecipient = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue) {
      addRecipient(inputValue.trim());
      e.preventDefault();
    }
  };

  const addRecipient = (email: string) => {
    if (email && !recipients.includes(email)) {
      const updatedRecipients = [...recipients, email];
      setRecipients(updatedRecipients);
      onRecipientsChange?.(updatedRecipients);
      setInputValue("");
      setSuggestions([]);
    }
  };

  const handleRemoveRecipient = (email: string) => {
    const updatedRecipients = recipients.filter(
      (recipient) => recipient !== email
    );
    setRecipients(updatedRecipients);
    onRecipientsChange?.(updatedRecipients);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    if (value) {
      const filteredSuggestions = sampleEmails.filter((email) =>
        email.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleDepartmentSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedDepartment = departments.find(
      (dept) => dept.name === e.target.value
    );
    if (selectedDepartment) {
      const newRecipients = selectedDepartment.emails.filter(
        (email) => !recipients.includes(email)
      );
      const updatedRecipients = [...recipients, ...newRecipients];
      setRecipients(updatedRecipients);
      onRecipientsChange?.(updatedRecipients);
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row gap-4">
        <div className="relative w-full md:w-auto">
          <input
            type="text"
            placeholder="Search recipient"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleAddRecipient}
            className="p-2 border border-gray-300 rounded-md focus:outline-none text-sm w-full"
          />
          {/* Suggestions Dropdown */}
          {suggestions.length > 0 && (
            <div className="absolute top-full mt-1 w-full bg-white border rounded-md shadow-lg z-10">
              {suggestions.map((email, index) => (
                <div
                  key={index}
                  onClick={() => addRecipient(email)}
                  className="px-4 py-2 cursor-pointer hover:bg-blue-100 text-sm"
                >
                  {email}
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Department Dropdown */}
        <div className="w-full md:w-auto">
          <select
            onChange={handleDepartmentSelect}
            className="p-2 border border-gray-300 rounded-md text-sm w-full"
            defaultValue=""
          >
            <option value="" disabled>
              Select Department
            </option>
            {departments.map((dept, index) => (
              <option key={index} value={dept.name}>
                {dept.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* Display Recipients */}
      <div className="w-full border rounded-md bg-white p-4 h-[100px]">
        <div className="flex flex-wrap gap-2">
          {recipients.map((email, index) => (
            <div
              key={index}
              className="flex items-center px-2 py-1 bg-gray-200 rounded-full text-sm"
            >
              <span className="mr-2 flex items-center justify-center w-6 h-6 rounded-full bg-blue-500 text-white font-bold">
                {email.charAt(0).toUpperCase()}
              </span>
              <span>{email}</span>
              <button
                onClick={() => handleRemoveRecipient(email)}
                className="ml-2 text-gray-600 hover:text-red-500"
              >
                &times;
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EmailRecipients;
