import React, { useEffect, useState } from "react";

import { Input, Pagination, Popover, Select, Typography } from "antd";
import { BsSearch } from "react-icons/bs";
import {
  BlockOutlined,
  FilterOutlined,
  InsertRowBelowOutlined,
} from "@ant-design/icons";
import EmployeeManagementTableView from "../../table/EmployeeManagementTableView";
import EmployeeMngmntImport from "../../reuseables/EmployeeMngmntImport";
import EmployeeMngmentExport from "../../reuseables/EmployeeMngmentExport";
import EmployeeMngmentCard from "../../reuseables/EmployeeMngmentCard";
import { ObjType } from "./Type";
import { useLazyFetchEmployeeQuery } from "../../../store";
const apiDomain = process.env.REACT_APP_API_DOMAIN;

// when theres a data from api the dev can replace it on the component where you need to destruct the obj
type AllEmployeeProps = {
  active: number;
};

type MapData = {
  position: string;
  name: string;
  profile: string;
  department: string;
  id: number;
};
const AllEmployee = ({ active }: AllEmployeeProps) => {
  console.log(active);
  const { Text } = Typography;
  const [toggleView, setToggleView] = useState("ListType");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [
    trigger,
    { data: fetchEmployees, isSuccess, isLoading, isFetching, isError },
  ] = useLazyFetchEmployeeQuery();

  let content: React.ReactNode = <></>;
  useEffect(() => {
    trigger({ page, pageSize });
    if (isLoading && isFetching) {
      console.log("loading...");
    } else {
      // console.log(fetchEmployees);
      console.log("done loading");
    }
  }, [active, page, pageSize, isLoading, isSuccess, trigger]);

  if (isSuccess && !isLoading) {
    const data = fetchEmployees?.data?.results.map(
      (item: any, index: number) => ({
        id: item.id,
        profile: item.profile_image
          ? item.profile_image.includes("null")
            ? ""
            : `${apiDomain}${item.profile_image}`
          : "",
        employee_id: item.employee_id,
        name: `${item.user.first_name} ${item.user.last_name}`,
        position: item.job_title?.name,
        department: item.department?.name,
        status: item.status,
      })
    );
    content = (
      <>
        {toggleView === "ListType" && (
          <EmployeeManagementTableView
            count={fetchEmployees.data.count}
            dataTables={data}
            page={page}
            setPage={setPage}
            setPageSize={setPageSize}
            pageSize={pageSize}
          />
        )}
        {toggleView === "CardType" && (
          <div className="grid grid-flow-row grid-cols-4 mt-12 gap-10">
            {data.map(
              (
                { position, name, profile, department, id }: MapData,
                index: number
              ) => (
                <EmployeeMngmentCard key={index}>
                  <EmployeeMngmentCard.Profile source={profile} />
                  <div className=" flex flex-col justify-center items-center">
                    <EmployeeMngmentCard.Name name={name} id={id} />
                    <EmployeeMngmentCard.Position position={position} />
                    <EmployeeMngmentCard.Department department={department} />
                  </div>
                  {EmployeeMngmentCard.Social && (
                    <EmployeeMngmentCard.Social viber="#" email="#" />
                  )}
                </EmployeeMngmentCard>
              )
            )}
          </div>
        )}
        <Pagination
          className="flex justify-end mt-4"
          current={page}
          pageSize={pageSize}
          total={fetchEmployees.data.count}
          onChange={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
        />
      </>
    );
  } else {
    content = <div>Loading...</div>;
  }

  //toggle view to card or list type
  const changeView = () => {
    toggleView === "ListType"
      ? setToggleView("CardType")
      : setToggleView("ListType");
  };

  return (
    <>
      <div className=" mt-10 space-y-5">
        <div className=" flex justify-between items-center mt-7 space-x-5 mr-4">
          <div className=" space-x-5 ">
            <Input
              placeholder="Search.."
              className="custom-style-input w-72 bgcy"
              suffix={<BsSearch />}
            />
            <Select
              defaultValue="All position"
              className=" font-sans w-40"
              options={[
                { value: "jack", label: "Jack" },
                { value: "All Office", label: "All Office", disabled: true },
              ]}
            />
            <Select
              defaultValue="All department"
              className=" font-sans w-40"
              options={[
                { value: "jack", label: "Jack" },
                { value: "All Office", label: "All Office", disabled: true },
              ]}
            />
            <Select
              defaultValue="All Status"
              className=" font-sans w-40"
              options={[
                { value: "jack", label: "Jack" },
                {
                  value: "All Status",
                  label: "All Status",
                  disabled: true,
                },
              ]}
            />
          </div>
          <div className=" flex items-center space-x-4">
            <EmployeeMngmntImport />
            <Popover content={<Text className=" font-sans">Clear filter</Text>}>
              <FilterOutlined className="text-base cursor-pointer text-[#0056d2] shadow hover:ring-1 hover:ring-[#0056d2] shadow-neutral-300 rounded-full p-2" />
            </Popover>
            <EmployeeMngmentExport />
            {toggleView === "CardType" && (
              <Popover content={<Text className=" font-sans">List view</Text>}>
                <InsertRowBelowOutlined
                  className="text-base cursor-pointer text-[#0056d2] shadow hover:ring-1 hover:ring-[#0056d2] shadow-neutral-300 rounded-full p-2"
                  onClick={changeView}
                />
              </Popover>
            )}
            {toggleView === "ListType" && (
              <Popover content={<Text className=" font-sans">Card view</Text>}>
                <BlockOutlined
                  className="text-base cursor-pointer text-[#0056d2] shadow hover:ring-1 hover:ring-[#0056d2] shadow-neutral-300 rounded-full p-2"
                  onClick={changeView}
                />
              </Popover>
            )}
          </div>
        </div>
        {content}
      </div>
    </>
  );
};

export default AllEmployee;
