import React, { Component } from 'react'
import {Memo} from '../../../utils/type';
import MemoModal from '../../modals/WhatsNew/MemoModal';
const apiDomain = process.env.REACT_APP_API_DOMAIN || '';
interface Props {
  data: Memo[];
}

const CardMemos = ({data}: Props) => {
  console.log(data);
  return (
    <div>
      <div className="bg-gray-50 p-8 rounded-md shadow-md">
      <div className="flex">
        <img src="../images/whatsNew/whatNewImagetableHeader.png" alt="What's New" className="mr-2 h-18 w-20" />
        <h2 className="text-2xl font-bold text-orange-500">Memos</h2>
      </div>
      <p className="text-gray-600 mb-8">
        Stay updated with the latest features, improvements, and announcements! Explore our ‘What’s New’ page to discover fresh content, product updates, and exciting developments happening in our community.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {data.map((memo: Memo) => (
          // Ensure a unique key for each memo
          <div key={memo.id} className="bg-white rounded-md shadow-md p-4">
            <img src={`${apiDomain}${memo.cover_photo}` ?? "../images/whatsNew/whatsNew3.png"} alt="Memo" className="rounded-md mb-4" />
            <div className="text-orange-500 text-sm font-semibold mb-1">Department</div>
            <MemoModal title={memo.title} id={memo.id}/>
            {/* <h3 className="text-xl font-bold text-gray-800 mb-2">{memo.title}</h3> */}
            <p className="text-gray-600 mb-4">{memo.description}</p>
            <div className="flex items-center">
              <img src="../images/jimmy.png" alt="Author" className="rounded-full w-10 h-10 mr-3" />
              <div>
                <p className="text-sm font-semibold text-gray-800">Uploaded By: {memo.uploaded_by.first_name} {memo.uploaded_by.last_name}</p>
                <p className="text-sm text-gray-500">{new Date(memo.date_uploaded).toLocaleDateString()}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Dropdown */}
      <div className="mt-8 flex justify-end">
          <select className="border border-gray-300 rounded-md px-3 py-2 text-gray-700">
          <option>September 2024</option>
          {/* Add more options as needed */}
          </select>
      </div>
      </div>
    </div>
  )
}
export default CardMemos;
