import React, { useMemo, useState } from "react";
import { Dialog, Box, Typography, Button, IconButton, Divider } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useMemoDetailedQuery, useMemoListOthersPageQuery } from "../../../store";
import {Memo} from '../../../utils/type';
const apiDomain = process.env.REACT_APP_API_DOMAIN || '';

type MemoModalProps = {
    title: string;
    id: number
};


const MemoModal: React.FC<MemoModalProps> = ({ title, id }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let other_image_list = [
    {
        "id": 1,
        "url": "/images/whatsNew/whatsNew1.png",
        "value": 1
    },
    {
        "id": 2,
        "url": "/images/whatsNew/whatsNew2.png",
        "value": 2
    },
    {
        "id": 3,
        "url": "/images/whatsNew/whatsNew3.png",
        "value": 3
    },
    {
        "id": 4,
        "url": "/images/whatsNew/event1.jpg",
        "value": 4
    }
  ]

  let mome_detailed = useMemoDetailedQuery({
    id
  });

  let momeOther = useMemoListOthersPageQuery({
    page: 1,
    pageSize: 4,
    id:id
  });


  return (
    <div>
      {/* Button to open the modal */}
      <Typography variant="body1" className="text-xl font-bold text-gray-800 mb-2" onClick={handleOpen}>
        {title}
      </Typography>

      <Dialog open={open} onClose={handleClose} fullScreen>
        <Box sx={{ position: "relative", bgcolor: "background.paper", overflowY: "auto" }}>
          {/* Close Button */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              backgroundColor: "error.main",
              color: "white",
              borderRadius: "50%",
              width: 36,
              height: 36,
              "&:hover": { backgroundColor: "error.dark" },
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Blog Post Content */}
          <Box sx={{ p: 4, maxWidth: "800px", mx: "auto" }}>
            {/* Hero Image */}
            <Box
              component="img"
              src={`${apiDomain}${mome_detailed.data?.data.cover_photo}` ?? "/images/whatsNew/whatsNew2.png"}
              alt="Blog Hero"
              sx={{
                width: "100%",
                borderRadius: 2,
                mb: 3,
              }}
            />

            {/* Title and Date */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <Typography variant="h4" fontWeight="bold">
                {mome_detailed.data?.data.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {mome_detailed.data?.data.date_uploaded}
              </Typography>
            </Box>

            {/* Category */}
            <Typography variant="body2" color="orange" fontWeight="bold" mb={2}>
              {mome_detailed.data?.data.department.name}
            </Typography>

            {/* Content */}
            <Typography variant="body1" color="textSecondary" paragraph>
              {
                mome_detailed.data?.data.description
              }
            </Typography>

            {/* Inline Images */}
            <Box display="flex" gap={2} mb={4}>
              <Box
                component="img"
                src="/images/whatsNew/whatsNew1.png"
                alt="Content Image"
                sx={{ width: "48%", borderRadius: 2 }}
              />
              <Box
                component="img"
                src="/images/whatsNew/whatsNew3.png"
                alt="Content Image"
                sx={{ width: "48%", borderRadius: 2 }}
              />
            </Box>

            {/* Author Section */}
            <Box display="flex" alignItems="center" mb={4}>
              <Box
                component="img"
                src="/images/jimmy.png"
                alt="Author"
                sx={{ width: 50, height: 50, borderRadius: "50%", mr: 2 }}
              />
              <Typography variant="subtitle1" color="textPrimary">
                {mome_detailed.data?.data.uploaded_by.first_name} {mome_detailed.data?.data.uploaded_by.last_name}
              </Typography>
            </Box>

            {/* Divider */}
            <Divider sx={{ my: 3 }} />

            {/* Read Others Section */}
            <Typography variant="h5" fontWeight="bold" color="primary" textAlign="center" mb={2}>
              Read Others
            </Typography>
            <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={3}>
            {momeOther.isSuccess && momeOther.data?.results?.length > 0 && (
              momeOther.data.results.map((memo: Memo) => (
                <Box
                  key={memo.id}
                  sx={{
                    p: 2,
                    border: 1,
                    borderRadius: 2,
                    // borderColor: memo.id === 4 ? "purple" : "grey.300",
                  }}
                >
                  <Box
                    component="img"
                    src={`${apiDomain}${memo.cover_photo}` ?? "/images/whatsNew/whatsNew2.png"}
                    alt="Blog Thumbnail"
                    sx={{ width: "100%", height: 100, objectFit: "cover", borderRadius: 1, mb: 1 }}
                  />
                  <Typography variant="subtitle1" fontWeight="bold">
                    {memo.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {memo.description}
                  </Typography>
                </Box>
              ))
            )}
            </Box>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default MemoModal;
