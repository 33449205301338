import React, { useState } from "react";
import CustomContainer from "../../reuseables/CustomContainer";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
  MdOutlineEventNote,
} from "react-icons/md";
import { Button } from "antd";
import dayjs from "dayjs";
import moment, { Moment } from "moment";
import {
  PiHeartbeatLight,
  PiMegaphoneLight,
  PiNewspaperClippingLight,
} from "react-icons/pi";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { CiCalendar } from "react-icons/ci";
import { FaRegHeart } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import DoughnutChart from "./Charts/DoughnutChart";
import MeterChart from "./Charts/MeterChart";
import BarChart from "./Charts/BarChart";
import { FaRegUser } from "react-icons/fa6";
import { FiCopy } from "react-icons/fi";

export default function DashboardBody() {
  const [active, setActive] = useState(true);
  const [date, setDate] = useState(dayjs());
  const [currentWeekStart, setCurrentWeekStart] = useState<moment.Moment>(
    moment().startOf("isoWeek")
  );

  // Function to go to the previous month
  const handlePrevMonth = () => {
    setDate(date.subtract(1, "month"));
  };

  // Function to go to the next month
  const handleNextMonth = () => {
    setDate(date.add(1, "month"));
  };

  const getWeekDays = (startOfWeek: moment.Moment) => {
    const days = [];
    // Use isoWeek to start the week from Monday
    const startOfMondayWeek = startOfWeek.clone().startOf("isoWeek");

    for (let i = 0; i < 7; i++) {
      const day = startOfMondayWeek.clone().add(i, "days");
      // days.push(startOfMondayWeek.clone().add(i, "days"));
      // if (day.day() !== 0 && day.day() !== 6) {
      // }
      days.push(day);
    }
    return days;
  };
  const weekdays = getWeekDays(currentWeekStart);

  const events = [
    {
      date: "Sept 7",
      time: "1:00 PM - 8:00 PM",
      event: "Product Launch",
      location: "Conference Hall",
    },
    {
      date: "Sept 12",
      time: "8:00 AM - 12:00 PM",
      event: "Board Meeting",
      location: "Room 101",
    },
    {
      date: "Oct 8",
      time: "8:00 AM - 12:00 PM",
      event: "Team Building",
      location: "Quezon City",
    },
  ];

  const getIcon = (cat: number) => {
    // wellness 1
    // health 2
    //talks 3
    // client 4
    // spotlights 5
    const icons = [
      "",
      <FaRegHeart />,
      <PiHeartbeatLight />,
      <PiMegaphoneLight />,
      <FaRegUser />,
      <FiCopy />,
    ];
    return icons[cat];
  };
  const compNews = [
    {
      description:
        "Introducing our innovative Employee Wellness Program, prioritizing team well-being",
      date: "Today",
      user: "Mike Taylor",
      category: 1,
    },
    {
      description: "Yoga Retreat Day: Nurturing Physical and Mental Resilience",
      date: "Today",
      user: "Mike Taylor",
      category: 2,
    },
    {
      description:
        "Tech Talks Series: Exploring Cutting-Edge Innovations in the Workplace",
      date: "11 Nov",
      user: "Mike Taylor",
      category: 3,
    },
    {
      description:
        "Client Success Stories: Showcasing the impact of our products and Services",
      date: "10 Nov",
      user: "Mike Taylor",
      category: 4,
    },
    {
      description:
        "Spotlight on Innovation: Showcasing Employee-Driven Creative Projects",
      date: "09 Nov",
      user: "Mike Taylor",
      category: 5,
    },
  ];
  return (
    <CustomContainer>
      <div className="grid grid-cols-10 gap-4 mt-4">
        <div className="col-span-8 bg-white rounded-md p-4">
          <div className="flex justify-between">
            <div className="flex gap-4">
              <p className="flex items-center gap-2">
                <span className="text-xl">
                  <MdOutlineEventNote />
                </span>
                <span className="text-lg font-semibold">Upcoming Events</span>
              </p>
              <div className="flex gap-4 justify-between items-center">
                <p className="flex text-2xl font-semibold">
                  <span>{date.format("MMMM YYYY")}</span>
                </p>
                {/* WEEK MONTH BUTTON */}
                <div
                  onClick={() => setActive(!active)}
                  className="relative w-36 h-8 flex items-center cursor-pointer overflow-hidden rounded-md bg-blue-500"
                >
                  <div
                    className={`absolute top-0 left-0 h-full w-1/2 transition-transform duration-500 ease-in-out ${
                      active ? "translate-x-full" : "translate-x-0"
                    } bg-green-300`}
                  ></div>
                  <div className="z-10 py-2 px-4 flex-1 flex items-center justify-center text-sm font-bold text-white">
                    Week
                  </div>
                  <div className="z-10 py-2 px-4 flex-1 flex items-center justify-center text-sm font-bold text-white">
                    Month
                  </div>
                </div>
                {/* WEEK MONTH BUTTON END */}
              </div>
            </div>
            <div className="flex gap-4 justify-between items-center">
              <div className="flex gap-3 m-4 ml-auto">
                <Button onClick={handlePrevMonth} className="font-bold">
                  <MdOutlineArrowBackIos />
                </Button>
                <Button onClick={handleNextMonth} className="font-bold">
                  <MdOutlineArrowForwardIos />
                </Button>
                <Button onClick={() => setDate(dayjs())}>Today</Button>
              </div>
            </div>
          </div>
          {/* customed calendar week */}
          <div className="flex flex-grow mt-4">
            {weekdays.map((day, index) => {
              const today =
                new Date().toDateString() === day.toDate().toDateString();
              return (
                <div className="flex-1">
                  <div
                    className={`${
                      today ? "font-semibold text-cyan-500" : ""
                    } text-center uppercase mb-3`}
                  >
                    {day.format("ddd")}
                  </div>
                  <div
                    key={index}
                    className={`flex flex-col items-center text-center px-4 py-2 border h-36 ${
                      today
                        ? "border-2 border-cyan-500 rounded-md text-xl scale-100 bg-cyan-100"
                        : ""
                    }`}
                    style={{ minWidth: "80px" }}
                  >
                    <div className={`text-lg ${today ? "text-cyan-700" : ""}`}>
                      {day.date()}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* TAGS */}
          <div className="flex space-x-2 mt-4">
            <p className="flex gap-3 items-center">
              <span className="p-3 flex justify-center items-center bg-blue-500 text-white rounded-full text-lg">
                <PiMegaphoneLight />
              </span>
              <span className="text-xl font-semibold">Events</span>
            </p>
            <p className="flex gap-3 items-center">
              <span className="p-3 flex justify-center items-center bg-blue-500 text-white rounded-full text-lg">
                <LiaBirthdayCakeSolid />
              </span>
              <span>Birthday</span>
            </p>
            <p className="flex gap-3 items-center">
              <span className="p-3 flex justify-center items-center bg-blue-500 text-white rounded-full text-lg">
                <CiCalendar />
              </span>
              <span>Others</span>
            </p>
          </div>
        </div>
        <div className="col-span-2 bg-white rounded-md p-4">
          <p>
            <span className="text-xl font-semibold">Total Employees</span>
          </p>
          <div>
            <MeterChart />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-10 gap-4 mt-4">
        <div className="col-span-3 bg-white p-4 rounded-md">
          <p className="flex gap-3 items-center">
            <span className="p-3 flex justify-center items-center bg-blue-500 text-white rounded-full text-lg">
              <PiMegaphoneLight />
            </span>
            <span className="text-xl">Eventes</span>
          </p>
          {events.map((item, index) => (
            <div>
              <p className="my-4">
                <span>{item.date}</span>
              </p>
              <div className="bg-gray-50 p-2 flex rounded-md">
                <div className="border-r-4 border-violet-400 mr-4 min-h-24">
                  <p className="w-24">
                    <span className="font-semibold text-lg">{item.time}</span>
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="flex flex-col">
                    <span className="font-semibold">{item.event}</span>
                    <span className=" flex items-center gap-2">
                      <span className="font-bold text-gray-600">
                        <CiLocationOn />
                      </span>
                      <span className="text-gray-400">{item.location}</span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="col-span-3 bg-white p-4 rounded-md">
          <p className="flex gap-3 items-center">
            <span className="p-3 flex justify-center items-center bg-blue-500 text-white rounded-full text-lg">
              <LiaBirthdayCakeSolid />
            </span>
            <span className="text-xl font-semibold">Birthdays</span>
          </p>
          {events.map((item, index) => (
            <div>
              <p className="my-4">
                <span>{item.date}</span>
              </p>
              <div className="bg-gray-50 p-2 flex rounded-md">
                <div className="border-r-4 border-violet-400 mr-4 min-h-24">
                  <p className="w-24">
                    <span className="font-semibold text-lg">{item.time}</span>
                  </p>
                </div>
                <div className="flex items-center">
                  <p className="flex flex-col">
                    <span className="font-semibold">{item.event}</span>
                    <span className=" flex items-center gap-2">
                      <span className="font-bold text-gray-600">
                        <CiLocationOn />
                      </span>
                      <span className="text-gray-400">{item.location}</span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="col-span-2 grid grid-rows-2 gap-4 rounded-md">
          <div className="bg-white p-4 rounded-md row-span-1">
            <p className="text-xl font-semibold">
              <span>Turnover Rate</span>
            </p>
            <div className="flex items-center justify-center mt-4">
              <DoughnutChart percentage={28} />
            </div>
          </div>
          <div className="bg-white p-4 rounded-md row-span-1">
            <p className="text-xl font-semibold">
              <span>Employee File Completion</span>
            </p>
            <div className="flex items-center justify-center mt-4">
              <DoughnutChart percentage={28} />
            </div>
          </div>
        </div>
        <div className="col-span-2 grid grid-rows-2 gap-4">
          <div className="bg-white p-4 rounded-md row-span-1">
            <p className="text-xl font-semibold">
              <span>Occupancy Rate</span>
            </p>
            <div className="flex items-center justify-center mt-4">
              <DoughnutChart percentage={72} />
            </div>
          </div>
          <div className="bg-white p-4 rounded-md row-span-1">
            <p className="text-xl font-semibold">
              <span>Compliance Rate</span>
            </p>
            <div className="flex items-center justify-center mt-4">
              <DoughnutChart percentage={72} />
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-10 gap-4 mt-4">
        <div className="col-span-6 bg-white p-4 rounded-md">
          <div className="flex justify-between">
            <p className="flex gap-3 text-xl font-semibold items-center">
              <span>
                <PiNewspaperClippingLight />
              </span>
              <span>Company News</span>
            </p>
            {/* this should be link TODO */}
            <Button type="text" className="text-cyan-500">
              View All{" "}
              <span>
                <MdOutlineArrowForwardIos />
              </span>
            </Button>
          </div>
          <div className="mt-4">
            {compNews.map((item, index) => (
              <div
                className={`flex gap-8 px-3 py-4 ${
                  index + 1 === compNews.length ? "" : "border-b-2"
                }`}
                key={`${item.description}${index}`}
              >
                <div className="flex justify-center items-center text-2xl text-cyan-500">
                  {getIcon(item.category)}
                </div>
                <div>
                  <p className="text-lg">{item.description}</p>
                  <p className="flex text-gray-400">
                    <span className="border-r-2 pr-2">{item.date}</span>
                    <span className="pl-2">{item.user}</span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-4 bg-white p-4 rounded-md">
          <p className="text-xl font-semibold text-start">
            <span>Departmental Headcount</span>
          </p>
          <div className="flex flex-col justify-center items-center mt-4">
            <BarChart />
          </div>
        </div>
      </div>
    </CustomContainer>
  );
}
