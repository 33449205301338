import React, { useState } from "react";
import {TextField } from "@mui/material";
import { FiMapPin } from "react-icons/fi";
import { IoMdInformationCircleOutline } from "react-icons/io";
import MultipleUploadImages from "../../inputs/MultipleUploadImages";
import { BsPersonCheckFill } from "react-icons/bs";
import EmailRecipients from "../../inputs/EmailRecipients";
import { IoNewspaperOutline } from "react-icons/io5";
import { MdPersonAddAlt } from "react-icons/md";
import {
    useAddEventMutation, useCityListQuery,
    useEventCategoryQuery, useFetchDepartmentQuery, useProvinceListQuery,
    useRegionListQuery
} from "../../../store";
import utils from '../../../utils/core';
import SearchDropdown from "../../inputs/SearchDropdown";
import GoogleMapIframe from "../../inputs/GoogleMapIframe";
import DateTimeRange from "../../inputs/DateTimeRange";
import SingleDateTime from "../../inputs/SingleDateTime";
import { Employee } from "../../../utils/type";


export type ImageType = {
    url: string;
    name: string;
    loading: boolean;
};

const sampleEmails = [
    "patricia@example.com",
    "phoenix@example.com",
    "severly@example.com",
    "pdc.techcare@example.com",
    "john.doe@example.com",
    "jane.doe@example.com",
    "reyan@mailinator.com",
  ];
  
  const departments = [
    {
      name: "Engineering",
      emails: ["eng1@example.com", "eng2@example.com", "eng3@example.com"],
    },
    {
      name: "Marketing",
      emails: ["mkt1@example.com", "mkt2@example.com", "mkt3@example.com"],
    },
    {
      name: "Sales",
      emails: ["sales1@example.com", "sales2@example.com", "sales3@example.com"],
    },
  ];

const AddEvent = () => {
    // usestate
    const [limitedParticipants, setLimitedParticipants] = useState(true);
    const [limitedPeriod, setLimitedPeriod] = useState(false);
    const [publishNow, setPublishNow] = useState(false);
    const [recipientsType, setRecipientsType] = useState<boolean>(false);
    const [image, setImage] = useState<string | null>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [images, setImages] = useState<ImageType[]>([]);
    const [startDateTime, setStartEndTime] = useState<string>("");
    const [endEndDateTime, setEndDateTime] = useState<string>("");

    const [streetAddress ,setStreetAddress] = useState<string>('');
    const [regionSelected, setRegionSelected] = useState<{ value: string; label: string } | null>(null);
    const [provinceSelected, setprovinceSeleted] = useState<{ value: string; label: string } | null>(null);
    const [citySelected, setCitySelected] = useState<{ value: string; label: string } | null>(null);
    const [lparticipants, setLParticipants] = useState<number| null>(null);
    const [lTimePeriod, setLTimePeriod] = useState<number| null>(null);
    const [recipients, setRecipients] = useState<string[]| []>([]);
    const [publishDate, setPublishDate] = useState<string>("");

    const [selectedDepartment, setSelectedDepartment] = useState<string>("");


    // end usestate

    const savedEmployee: Employee = JSON.parse(
        localStorage.getItem("employee_detail") || '{}'
    );

    // function input
    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file instanceof Blob) {
            setImage(URL.createObjectURL(file));
        }
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value);
    }

    const handleRemoveImage = () => {
        setImage(null);
    };

    const handleImagesChange = (newImages: ImageType[]) => {
        setImages(newImages);
    };


    const handleStartTimeChange = (value: string) => {
        setStartEndTime(value);
    };

    const handleEndTimeChange = (value: string) => {
        setEndDateTime(value);
    };


    const handleStreeAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStreetAddress(event.target.value);
    };
    const handleRegionChange = (selected: { value: string; label: string } | null) => {
        if (selected) {
            setRegionSelected(selected);
        }
    };
    const handleProvincenChange = (selected: { value: string; label: string } | null) => {
        if (selected) {
            setprovinceSeleted(selected);
        }
    };
    const handleCityChange = (selected: { value: string; label: string } | null) => {
        if (selected) {
            setCitySelected(selected);
        }
    };

    const handleLimitParticipants = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value ? parseInt(event.target.value, 10) : null;
        setLParticipants(value);
    };

    const handleLimitTimePeriod= (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value ? parseInt(event.target.value) : null;
        setLTimePeriod(value);
    }

    const handleRecipientsChange = (recipients: string[]) => {
        setRecipients(recipients)
    };

    const eventLocationConcat = (
        streetAddress: string,
        regionSelected: { value: string; label: string } | null,
        provinceSelected: { value: string; label: string } | null,
        citySelected: { value: string; label: string } | null
    ): string => {
        const region = regionSelected?.label || "";
        const province = provinceSelected?.label || "";
        const city = citySelected?.label || "";
        console.log( `${streetAddress}, ${city}, ${province}, ${region}`.replace(/(, )+/g, ", ").trim())
        return `${streetAddress}, ${city}, ${province}, ${region}`.replace(/(, )+/g, ", ").trim();
    };

    const handlePublishDateChange = (value: string) => {
        setPublishDate(value);
    };

    const handleDepartmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedDepartment(event.target.value);
    };

    // end of function input

    // Api function call
    let [add_event] = useAddEventMutation();

    const handlePost = async () => {
        // Set loading state to true
        setLoading(true);

        const formData = new FormData();

        const fields: Record<string, string | object | any[] | File | Blob | undefined> = {
            client_id: savedEmployee.client.id.toString(),
            user_id: savedEmployee.user.id.toString(),
            is_lp: limitedParticipants?.toString() || undefined,
            image: image || undefined,
            name: name || undefined,
            description: description || undefined,
            images: images || undefined,
            start_date: startDateTime || undefined,
            end_date: endEndDateTime || undefined,
            department: selectedDepartment || undefined,
            stree_address: streetAddress || undefined,
            regionSelected: String(regionSelected?.value) || undefined,
            provinceSelected: String(provinceSelected?.value) || undefined,
            citySelected: String(citySelected?.value) || undefined,
            l_participant: lparticipants?.toString() || undefined,
            l_item_period: lTimePeriod?.toString() || undefined,
            recipients: recipients || undefined,
            publish_date: publishDate || undefined,
        };

        Object.entries(fields).forEach(([key, value]) => {
            if (value !== undefined) {
                if (Array.isArray(value)) {
                    formData.append(key, JSON.stringify(value));
                } else if (typeof value === "object" && !(value instanceof Blob)) {
                    formData.append(key, JSON.stringify(value));
                } else if (typeof value === "string" || value instanceof Blob) {
                    formData.append(key, value);
                }
            }
        });

        try {
          const addE = await add_event(formData).unwrap();
          utils.api_response(addE.success, addE.message);
        } catch (error) {
          console.error("Error posting memo:", error);
        } finally {
          setLoading(false);
        }
    };

    // const event_category = useEventCategoryQuery({});
    const regionList = useRegionListQuery({});
    const provinceList = useProvinceListQuery({});
    const cityList = useCityListQuery({});
    const departmentApi = useFetchDepartmentQuery("");
    // End Api function call

    return (
        <div className="flex h-screen p-8 pb-16">
            {/* Sidebar */}
            <aside className="w-1/4 bg-white p-6 border-r">
                <div className="mb-4">
                    <h2 className="text-xl font-semibold">Add Event</h2>
                    <p className="text-gray-500">Last update</p>
                    <p className="text-gray-800 font-bold">Monday, June 06 | 06:42 AM</p>
                    <p className="text-green-500 mt-1">Status: Draft</p>
                </div>
                <nav>
                    <ul>
                        <li className="py-2 text-lg font-medium">CREATE EVENT</li>
                        <li className="py-1 text-gray-600">Upload Cover</li>
                        <li className="py-1 text-gray-600">Event Details</li>
                        <li className="py-1 text-gray-600">Location</li>
                        <li className="py-2 text-lg font-medium mt-4">PUBLISH EVENT</li>
                        <li className="py-1 text-gray-600">Registration</li>
                        <li className="py-1 text-gray-600">Recipients</li>
                        <li className="py-1 text-gray-600">Review and Publish</li>
                    </ul>
                </nav>
            </aside>
            {/* End of sidebar */}

            {/* Main Content */}
            <main className="flex-1 p-8">
                {/* Upload Cover Section */}
                <h2 className="text-2xl font-semibold text-gray-800 flex items-center">
                    <span className="mr-2 text-blue-600"><MdPersonAddAlt/></span> Upload cover
                </h2>
                <p className="text-gray-500 text-sm mt-1 mb-4">
                    upload the event cover to capture you audience's attention
                </p>
                <div className="flex flex-col mb-4 items-center p-4 bg-gray-100 rounded-lg shadow-md">
                    {image ? (
                        <div className="relative w-full max-w-sm">
                            <img
                                src={image}
                                alt="Uploaded Preview"
                                className="w-full h-auto rounded-md"
                            />
                            <div className="absolute top-2 right-2 flex space-x-2">
                                <button
                                onClick={handleRemoveImage}
                                className="px-2 py-1 bg-red-500 text-white rounded"
                                >
                                    Remove
                                </button>
                                <label className="px-2 py-1 bg-blue-500 text-white rounded cursor-pointer">
                                    Change
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    className="hidden"
                                />
                                </label>
                            </div>
                        </div>
                    ) : (
                        <label className="flex flex-col items-center justify-center w-full max-w-sm h-48 border-2 border-dashed border-gray-300 rounded-md cursor-pointer bg-white">
                        <span className="text-gray-500">Click to upload image</span>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            className="hidden"
                        />
                        </label>
                    )}
                </div>

                {/* Event Details Section */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold flex items-center">
                        <span className="mr-2 text-xl font-semibold text-blue-600">
                            <IoMdInformationCircleOutline />
                        </span>
                            Event Details
                    </h2>
                    <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        placeholder="Rock Revolt: A Fusion of Power and Passion"
                        onChange={handleNameChange}
                    />
                    <TextField
                        label="Description"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        placeholder="Provide essential event details"
                        margin="normal"
                        onChange={handleDescriptionChange}
                    />

                    <TextField
                        select
                        SelectProps={{ native: true }}
                        variant="outlined"
                        onChange={handleDepartmentChange}
                        value={selectedDepartment}
                    >
                        {departmentApi.isLoading && <option>Loading...</option>}
                        {departmentApi.isError && <option>Error loading departments</option>}
                        {!departmentApi.isLoading && !departmentApi.isError && <option value="">Select Department</option>}
                        {!departmentApi.isLoading &&
                        !departmentApi.isError &&
                        departmentApi.data?.data.map((category: { id: number; name: string }) => (
                            <option key={category.id} value={category.id}>
                            {category.name}
                            </option>
                        ))}
                    </TextField>

                </section>

                {/* Album Section */}
                <section className="mb-8">
                <h2 className="text-2xl font-semibold">Album</h2>
                    <MultipleUploadImages initialImages={images} onImagesChange={handleImagesChange}/>
                </section>

                {/* Date and Time Section */}
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold">Date and Time</h2>
                    <div className="flex space-x-4 mt-4">
                    <DateTimeRange
                        startLabel="Pick Start Time"
                        endLabel="Pick End Time"
                        defaultStartDateTime="2024-12-01T08:00"
                        defaultEndDateTime="2024-12-01T18:00"
                        onStartDateTimeChange={handleStartTimeChange}
                        onEndDateTimeChange={handleEndTimeChange}
                    />
                    </div>
                </section>
                {/* Event Location */}
                <div className="space-y-4 bg-gray-50 pb-8">
                    <h2 className="flex items-center text-2xl font-semibold">
                        <span role="img" aria-label="location" className="mr-2 text-blue-600"><FiMapPin /></span> Location
                    </h2>

                    <p className="text-gray-500 text-sm">
                        You can choose the location or pinpoint it on the map
                    </p>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                        {/* Left Column: Form */}
                        <div className="space-y-4">
                        {/* Street Address */}
                        <div>
                            <label className="block text-gray-700 font-medium">Street Address</label>
                            <input
                                type="text"
                                placeholder="Address"
                                className="w-full px-3 py-2 mt-1 border rounded-lg focus:outline-none focus:border-blue-500 bg-gray-100 text-gray-700"
                                onChange={handleStreeAddressChange}
                            />
                        </div>

                        {/* Region */}
                        <div>
                            <SearchDropdown
                                label="Region"
                                options={regionList.data?.data}
                                defaultValue={{ value: "0", label: "Select Region" }}
                                onChange={handleRegionChange}
                            />
                        </div>

                        {/* Province */}
                        <div>
                            <SearchDropdown
                                label="Province"
                                options={provinceList.data?.data}
                                defaultValue={{ value: "0", label: "Select Province" }}
                                onChange={handleProvincenChange}
                            />
                        </div>

                        {/* City */}
                        <div>
                            <SearchDropdown
                                label="City"
                                options={cityList.data?.data}
                                defaultValue={{ value: "0", label: "Select City" }}
                                onChange={handleCityChange}
                            />
                        </div>
                    </div>

                        {/* Right Column: Map */}
                        <div className="w-full h-full">
                            <GoogleMapIframe location={eventLocationConcat(
                                streetAddress,
                                regionSelected,
                                provinceSelected,
                                citySelected
                            )}/>
                        </div>
                    </div>
                </div>
                {/* Register */}
                <form className="space-y-6">
                    {/* Registration Section */}
                    <div>
                        <h2 className="text-2xl font-semibold text-gray-800 flex items-center">
                            <span className="mr-2 text-blue-600"><BsPersonCheckFill /></span> Registration
                        </h2>
                        <p className="text-gray-500 text-sm mt-1 mb-4">
                            Provide the registration settings
                        </p>

                        <div className="flex space-x-16 mb-2">
                            <div>
                                <label className="flex text-gray-700 ">
                                    <input
                                        type="checkbox"
                                        checked={!limitedParticipants}
                                        onChange={() => setLimitedParticipants(false)}
                                        className="form-checkbox text-blue-600 focus:ring-0 focus:ring-offset-0"
                                    />
                                    <span className="ml-2">Unlimited Participants</span>
                                </label>
                            </div>
                            <div>
                                <label className="flex items-center text-gray-700">
                                    <input
                                        type="checkbox"
                                        checked={limitedParticipants}
                                        onChange={() => setLimitedParticipants(true)}
                                        className="form-checkbox text-blue-600 focus:ring-0 focus:ring-offset-0"
                                    />
                                    <span className="ml-2">Limited Participants</span>
                                </label>
                                {limitedParticipants && (
                                    <div className="flex items-center mt-1 ml-8">
                                        <span className="text-gray-700 mr-2">Limited to</span>
                                        <input
                                            type="number"
                                            className="border rounded-md w-20 p-1 text-center border-gray-300 focus:border-blue-500 focus:outline-none"
                                            defaultValue="100"
                                            onChange={handleLimitParticipants}
                                        />
                                        <span className="ml-2 text-gray-700">participants</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex space-x-4 mt-4">
                            <div className="space-y-2 py-8">
                                <label className="flex items-center text-gray-700">
                                    <input
                                        type="checkbox"
                                        checked={!limitedPeriod}
                                        onChange={() => setLimitedPeriod(false)}
                                        className="form-checkbox text-blue-600 focus:ring-0 focus:ring-offset-0"
                                    />
                                    <span className="ml-2">Until full capacity</span>
                                </label>
                            </div>
                            <div className="space-y-2 py-8">
                                <label className="flex items-center text-gray-700">
                                    <input
                                        type="checkbox"
                                        checked={limitedPeriod}
                                        onChange={() => setLimitedPeriod(true)}
                                        className="form-checkbox text-blue-600 focus:ring-0 focus:ring-offset-0"
                                    />
                                    <span className="ml-2">Limited period</span>
                                </label>
                                {limitedPeriod && (
                                    <div className="flex items-center mt-2 ml-8">
                                        <input
                                            type="number"
                                            className="border rounded-md w-20 p-1 text-center border-gray-300 focus:border-blue-500 focus:outline-none"
                                            defaultValue="3"
                                            onChange={handleLimitTimePeriod}
                                        />
                                        <span className="ml-2 text-gray-700">Hours before the event starts</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Recipients Section */}
                    <div>
                        <h2 className="text-2xl font-semibold text-gray-800 flex items-center">
                            <span className="mr-2 text-blue-600"><MdPersonAddAlt/></span> Recipients
                        </h2>
                        <p className="text-gray-500 text-sm mt-1 mb-4">
                            Select users to invite to this event
                        </p>

                        <div className="space-y-2 py-4">
                            <EmailRecipients
                                sampleEmails={sampleEmails}
                                departments={departments}
                                onRecipientsChange={handleRecipientsChange}
                            />
                        </div>
                    </div>

                    {/* Publish Date Section */}
                    <div>
                        <h2 className="text-2xl font-semibold text-gray-800 flex items-center">
                            <span className="mr-2 text-blue-600"><IoNewspaperOutline /></span> Publish Date
                        </h2>
                        <p className="text-gray-500 text-sm mt-1 mb-4">
                            Provide the date of publish of the event
                        </p>

                        <div className="flex space-x-4">
                            <div className="space-y-2 py-4">
                                <label className="flex  text-gray-700">
                                    <input
                                        type="checkbox"
                                        checked={publishNow}
                                        onChange={() => setPublishNow(!publishNow)}
                                        className="form-checkbox text-blue-600 focus:ring-0 focus:ring-offset-0"
                                    />
                                    <span className="ml-2">Publish now</span>
                                </label>
                            </div>
                            <div className="space-y-2 py-4">
                                <label className="flex text-gray-700">
                                    <input
                                        type="checkbox"
                                        onChange={() => setPublishNow(true)}
                                        className="form-checkbox text-blue-600 focus:ring-0 focus:ring-offset-0"
                                    />
                                    <span className="ml-2">Schedule publish</span>
                                </label>
                                {publishNow && (
                                    <div className="flex items-center space-x-4 mt-2">
                                        <SingleDateTime
                                            label="Event Date & Time"
                                            defaultValue="2024-12-01T10:00"
                                            onChange={handlePublishDateChange}
                                        />
                                    </div>
                                )}
                            </div>
                            <hr/>
                        </div>

                        {/* Action Buttons */}
                        <div className="flex items-center space-x-4 mt-6 justify-center">
                            <button
                                type="submit"
                                className="bg-green-600 text-white py-2 px-6 rounded font-semibold hover:bg-green-700"
                                disabled={loading}
                                onClick={handlePost}
                            >
                                Publish {loading ? "Posting..." : "Post"}
                            </button>
                            <button
                                type="button"
                                className="bg-gray-200 text-gray-700 py-2 px-6 rounded font-semibold hover:bg-gray-300"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </main>
        </div>
  );
};

export default AddEvent;
