import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Provider } from "react-redux";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");
const clientFromStorage: any = localStorage.getItem("employee_detail");
const clientObj = JSON.parse(clientFromStorage);
const client = clientObj?.client?.id;

interface AddNewFiles {
  client: number;
  id: number | null;
  data: any;
}
const documentApi = createApi({
  reducerPath: "Documents",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  tagTypes: ["Documents"],
  endpoints(builder) {
    return {
      addNewDocuments: builder.mutation({
        query: (data) => {
          return {
            url: `/api/memodocs/add_docs/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["Documents"],
      }),
      addNewDocumentFiles: builder.mutation({
        query: ({ client, id, data }: AddNewFiles) => {
          console.log(id);
          return {
            url: `/api/memodocs/add_doc_files/?client_id=${client}&id=${id}`,
            headers: {
              Authorization: "token " + token,
            },
            method: "POST",
            body: data,
          };
        },
        invalidatesTags: ["Documents"],
      }),
      fetchDocumentList: builder.query({
        query: ({ client }) => {
          return {
            url: `/api/memodocs/get_docs/?client_id=${client}`,
            headers: {
              Authorization: "token " + token,
            },
            method: "GET",
          };
        },
        providesTags: ["Documents"],
      }),
      fetchDetailedDocument: builder.query({
        query: ({ client, id }) => {
          return {
            url: `/api/memodocs/get_detailed_docs/?client_id=${client}&id=${id}`,
            headers: { Authorization: `token ${token}` },
            method: "GET",
          };
        },
        providesTags: ["Documents"],
      }),
    };
  },
});

export const {
  useAddNewDocumentsMutation,
  useFetchDocumentListQuery,
  useLazyFetchDetailedDocumentQuery,
  useAddNewDocumentFilesMutation,
} = documentApi;
export { documentApi };
