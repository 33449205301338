import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const MeterChart = () => {
  const data = {
    labels: ["Regular", "Probationary", "Project Based"],
    datasets: [
      {
        label: "Employee Distribution",
        data: [65, 15, 20], // Percentages for each category
        backgroundColor: ["#5B8FF9", "#F7C30B", "#FA541C"], // Segment colors
        hoverBackgroundColor: ["#3A69C9", "#D6A308", "#C83210"], // Hover colors
        borderWidth: 1, // Border thickness
        circumference: 180,
        rotation: 270,
      },
    ],
  };

  const options = {
    responsive: true, // Ensures responsiveness
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          usePointStyle: true, // Make labels circular
          generateLabels: (chart: any) => {
            const dataset = chart.data.datasets[0];
            const total = dataset.data.reduce(
              (sum: number, val: number) => sum + val,
              0
            );

            return chart.data.labels?.map((label: any, index: number) => ({
              text: `${label}\n${(
                ((dataset.data[index] as number) / total) *
                100
              ).toFixed(1)}%`, // Add percentage below the label
              fillStyle: dataset.backgroundColor[index],
              pointStyle: "circle",
              hidden: false,
              datasetIndex: 0,
            }));
          },
        },
      },
      tooltip: {
        enabled: true, // Enable tooltips
      },
    },
    cutout: "70%", // Adjusts the size of the inner hole
  };

  return (
    <div className="w-[250px] h-[300px]">
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default MeterChart;
