import React, { useState } from "react";
import TextField from "@mui/material/TextField";

interface DateTimePickerProps {
  label?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
}

const SingleDateTime: React.FC<DateTimePickerProps> = ({
  label = "Select Date & Time",
  defaultValue = "",
  onChange,
}) => {
  const [dateTime, setDateTime] = useState<string>(defaultValue);

  const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDateTime(value);
    onChange?.(value);
  };

  return (
    <TextField
      label={label}
      type="datetime-local"
      value={dateTime}
      onChange={handleDateTimeChange}
      InputLabelProps={{ shrink: true }}
      fullWidth
    />
  );
};

export default SingleDateTime;
