import React from "react";
import { toast } from "react-toastify";
import crypto from "crypto";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
// Reusable function
export const CoreCustomToast = (type: boolean, message: string) => {
  console.log(type);
  if (type === true) {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      icon: <FaCheckCircle style={{ color: "green" }} />,
    });
  } else {
    toast.error(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      icon: <FaExclamationCircle style={{ color: "red" }} />,
    });
  }
};
