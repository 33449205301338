import React from "react";
import Header from "../../reuseables/Header";

export default function DashboardHeader() {
  return (
    <div>
      <Header>
        <div className=" flex items-center space-x-5">
          <Header.Title title="Welcome, Amantha 👋" />
          <Header.Image src="../images/dashboard-icon.png" />
        </div>
        {/* <div>
          <p>
            <span>
              "Either you urn the day or the day runs you." - Jim Rohn
            </span>
          </p>
        </div> */}
        <div className="flex items-center space-x-5"></div>
      </Header>
    </div>
  );
}
