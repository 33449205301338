import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Plugin,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

type DoughnutChartProps = {
  percentage: number;
};
const DoughnutChart = ({ percentage }: DoughnutChartProps) => {
  const data = {
    labels: [], // No labels
    datasets: [
      {
        data: [percentage, 100 - percentage], // Filled vs unfilled
        backgroundColor: ["#5B8FF9", "#E0E0E0"], // Dark color for filled, light for unfilled
        hoverBackgroundColor: ["#3A69C9", "#D3D3D3"], // Hover colors
        borderWidth: 0, // No border
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        enabled: false, // Disable tooltips for simplicity
      },
    },
    cutout: "70%", // Adjusts the size of the inner hole
  };

  // Custom plugin to show only the percentage in the center
  const centerTextPlugin: Plugin<"doughnut"> = {
    id: "centerText",
    beforeDraw: (chart) => {
      const { width } = chart;
      const { height } = chart;
      const ctx = chart.ctx;

      const activePercentage = chart.config.data.datasets[0].data[0] as number;

      ctx.save();
      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "#000";
      ctx.fillText(`${activePercentage}%`, width / 2, height / 2); // Display only the percentage
      ctx.restore();
    },
  };

  return (
    <div style={{ width: "200px", height: "200px" }}>
      <Doughnut
        data={data}
        options={options}
        plugins={[centerTextPlugin]} // Add the custom plugin
      />
    </div>
  );
};

export default DoughnutChart;
