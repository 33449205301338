import React, { useState, createContext, ReactNode, useContext } from "react";
import { useAddEmployeeMutation } from "../store";
import { CoreCustomToast } from "../utils/CoreCustomToast";
import { RcFile } from "antd/es/upload";

const employee_detailed = JSON.parse(
  localStorage.getItem("account_detail") || "{}"
);
const clientFromStorage: any = localStorage.getItem("employee_detail");
const clientObj = JSON.parse(clientFromStorage);
const client = clientObj?.client?.id;

type StepContextProps = {
  steps?: number;
  children: ReactNode;
};

type Inputs = {
  empInputs: {
    client: number | string;
    gender: number;
    age: string;
    birthday: string;
    first_name: string;
    last_name: string;
    middle_name: string;
    empId: string;
    email: string;
    address_1: string;
    address_2: string;
    cpioe: string;
    cpioe_contanct_no: string;
    cpioe_relationship: string;
    blood_type: string;
    civil_status: string;
    phone_no: string | null;
    nationality: string;
    paRegion: number | null;
    paRegionLabel: string | null;
    paProvince: number | null;
    paProvinceLabel: string | null;
    paCity: number | null;
    paCityLabel: string | null;
    paBrgy: number | null;
    paBrgyLabel: string | null;
    caRegion: number | null;
    caRegionLabel: string | null;
    caProvince: number | null;
    caProvinceLabel: string | null;
    caCity: number | null;
    caCityLabel: string | null;
    caBrgy: number | null;
    caBrgyLabel: string | null;
    getId: string | number;
    fieldOfStudy: string | null;
    institutionName: string | null;
    dateGrad: string | null;
    instAddress: string | null;
    highestEduc: number | null;
    fileEduc: any[];
    degree: number | null;
    sss: number | string | null;
    philId: string | null | number;
    pagIbigNo: string | number | null;
    tin: string | number | null;
    empStatus: string | number | null;
    level: number | null;
    empJobPos: number | string;
    empDepartment: number | string;
    empWorkArg: number | string | null;
    empManager: string | null;
    supervisor: string | null;
    dateHired: string | null;
    contractStartDate: string | null;
    contractEndDate: string | null;
    regularization: string | null;
    basicSalary: number;
    allowance: number;
    totalSalary: number | null;
    hmo: string | number | null;
    hmoTerm: number | null | string;
    transpo: number | null | string;
    transpoTerm: number | null | string;
    rent: number | null | string;
    rentTerm: number | null | string;
    telco: number | null | string;
    telcoTerm: number | null | string;
    avatar: null | RcFile;
  };

  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setEmpInputs: React.Dispatch<React.SetStateAction<Inputs["empInputs"]>>;
  submitData: (step: number) => Promise<any>;
};

export const multiStepContext = createContext<Inputs | undefined>(undefined);

const StepContext: React.FC<StepContextProps> = ({ steps, children }) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [empInputs, setEmpInputs] = useState<Inputs["empInputs"]>({
    client,
    getId: 0,
    gender: 0,
    avatar: null,
    age: "",
    birthday: "",
    first_name: "",
    last_name: "",
    phone_no: "",
    middle_name: "",
    empId: "",
    email: "",
    address_1: "",
    address_2: "",
    cpioe: "",
    cpioe_contanct_no: "",
    cpioe_relationship: "",
    blood_type: "",
    civil_status: "",
    nationality: "",
    paRegion: null,
    paRegionLabel: null,
    paProvince: null,
    paProvinceLabel: null,
    paCity: null,
    paCityLabel: null,
    paBrgy: null,
    paBrgyLabel: null,
    caRegion: null,
    caRegionLabel: null,
    caProvince: null,
    caProvinceLabel: null,
    caCity: null,
    caCityLabel: null,
    caBrgy: null,
    caBrgyLabel: null,
    fieldOfStudy: null,
    institutionName: null,
    dateGrad: "",
    instAddress: "",
    highestEduc: null,
    fileEduc: [],
    degree: null,
    sss: null,
    philId: null,
    pagIbigNo: null,
    tin: null,
    empStatus: null,
    level: null,
    empJobPos: "",
    empDepartment: "",
    empWorkArg: null,
    empManager: null,
    supervisor: null,
    dateHired: null,
    contractStartDate: null,
    contractEndDate: null,
    regularization: null,
    basicSalary: 0,
    allowance: 0,
    totalSalary: 0,
    hmo: 0,
    hmoTerm: "",
    transpo: 0,
    transpoTerm: "",
    rent: 0,
    rentTerm: "",
    telco: 0,
    telcoTerm: "",
  });

  const [addEmployee] = useAddEmployeeMutation();
  async function submitData(step: number) {
    console.log(step);
    if (step === 1) {
      const formData = new FormData();
      let data1 = {
        gender: empInputs.gender,
        age: empInputs.age,
        birthday: empInputs.birthday,
        first_name: empInputs.first_name,
        last_name: empInputs.last_name,
        middle_name: empInputs.middle_name,
        employee_id: empInputs.empId,
        email: empInputs.email,
        address_1: empInputs.address_1,
        address_2: empInputs.address_2,
        cpioe: empInputs.cpioe,
        cpioe_contact_no: empInputs.cpioe_contanct_no,
        cpioe_relation: empInputs.cpioe_relationship,
        blood_type: empInputs.blood_type,
        civil_status: empInputs.civil_status,
        phone_number: empInputs.phone_no,
        step: 1,
        client_id: client,
        profile_image: empInputs.avatar,
      };
      for (const key in data1) {
        const value = data1[key as keyof typeof data1];
        // console.log(key, value);
        formData.append(key, value as string);
      }

      const addEmpStep1 = await addEmployee(formData).unwrap();

      console.log(addEmpStep1);
      setEmpInputs((prev) => ({ ...prev, getId: addEmpStep1.data.id }));
      if (addEmpStep1.success === true) {
        console.log(addEmpStep1.message);
      }
    } else if (step === 2) {
      const formData = new FormData();
      let data2 = {
        client_id: client,
        program: empInputs.fieldOfStudy,
        school_name: empInputs.institutionName,
        date_graduated: empInputs.dateGrad,
        address: empInputs.instAddress,
        educ_type: empInputs.highestEduc,
        files: empInputs.fileEduc,
        step: 2,
        degree: empInputs.degree,
        id: empInputs.getId,
      };
      empInputs?.fileEduc.forEach((file) => {
        formData.append("files", file); // 'files' is the key name expected by the server
      });
      for (const key in data2) {
        const value = data2[key as keyof typeof data2];
        // console.log(key, value);
        formData.append(key, value as string);
      }

      const addEmpStep2 = await addEmployee(formData).unwrap();

      if (addEmpStep2.success === true) {
        console.log(addEmpStep2.message);
      }
    } else if (step === 3) {
      const formData = new FormData();
      let data3 = {
        client_id: client,
        sss_no: empInputs.sss,
        phil_no: empInputs.philId,
        pagibig_no: empInputs.pagIbigNo,
        tin_no: empInputs.tin,
        id: empInputs.getId,
        step: 3,
      };
      for (const key in data3) {
        const value = data3[key as keyof typeof data3];
        // console.log(key, value);
        formData.append(key, value as string);
      }

      const addEmpStep3 = await addEmployee(formData).unwrap();

      if (addEmpStep3.success === true) {
        console.log(addEmpStep3.message);
      }
    } else if (step === 4) {
      const formData = new FormData();
      let data4 = {
        client_id: client,
        employee_status: empInputs.empStatus,
        level: empInputs.level,
        job_title: empInputs.empJobPos,
        department: empInputs.empDepartment,
        work_arrangement: empInputs.empWorkArg,
        manager: empInputs.empManager,
        supervisor: empInputs.supervisor,
        date_hired: empInputs.dateHired,
        ctrctStartDate: empInputs.contractStartDate,
        ctrctEndDate: empInputs.contractEndDate,
        rglrzation: empInputs.regularization,
        basic_salary: empInputs.basicSalary,
        allowance: empInputs.allowance,
        step: 4,
        id: empInputs.getId,
      };

      for (const key in data4) {
        const value = data4[key as keyof typeof data4];
        // console.log(key, value);
        formData.append(key, value as string);
      }

      const addEmpStep4 = await addEmployee(formData).unwrap();

      if (addEmpStep4.success === true) {
        console.log(addEmpStep4.message);
      }
    } else if (step === 5) {
      const formData = new FormData();

      let data5 = {
        hmo_id: empInputs.hmo,
        empBene: [
          {
            bene: 1,
            amount: empInputs.hmoTerm,
            term: 0,
          },
          {
            bene: 2,
            amount: empInputs.rent,
            term: empInputs.rentTerm,
          },
          {
            bene: 3,
            amount: empInputs.telco,
            term: empInputs.telcoTerm,
          },
          {
            bene: 4,
            amount: empInputs.transpo,
            term: empInputs.transpoTerm,
          },
        ],
        id: empInputs.getId,
        step: 5,
        client_id: client,
      };

      for (const key in data5) {
        const value = data5[key as keyof typeof data5];

        // Convert non-string values to string
        let serializedValue: string;

        if (Array.isArray(value)) {
          serializedValue = JSON.stringify(value); // Serialize arrays as JSON strings
        } else {
          serializedValue = String(value); // Convert numbers or other primitives to strings
        }
        formData.append(key, serializedValue);
      }

      const addEmpStep5 = await addEmployee(formData).unwrap();
      console.log(addEmpStep5);
      CoreCustomToast(addEmpStep5.success, addEmpStep5.message);
      if (addEmpStep5.success === true) {
        console.log(addEmpStep5.message);
        setEmpInputs({
          client,
          getId: 0,
          gender: 0,
          age: "",
          birthday: "",
          first_name: "",
          last_name: "",
          phone_no: "",
          middle_name: "",
          empId: "",
          email: "",
          address_1: "",
          address_2: "",
          cpioe: "",
          cpioe_contanct_no: "",
          cpioe_relationship: "",
          blood_type: "",
          civil_status: "",
          nationality: "",
          paRegion: null,
          paRegionLabel: null,
          paProvince: null,
          paProvinceLabel: null,
          paCity: null,
          paCityLabel: null,
          paBrgy: null,
          paBrgyLabel: null,
          caRegion: null,
          caRegionLabel: null,
          caProvince: null,
          caProvinceLabel: null,
          caCity: null,
          caCityLabel: null,
          caBrgy: null,
          caBrgyLabel: null,
          fieldOfStudy: null,
          institutionName: null,
          dateGrad: "",
          instAddress: "",
          highestEduc: null,
          fileEduc: [],
          degree: null,
          sss: null,
          philId: null,
          pagIbigNo: null,
          tin: null,
          empStatus: null,
          level: null,
          empJobPos: "",
          empDepartment: "",
          empWorkArg: null,
          empManager: null,
          supervisor: null,
          dateHired: null,
          contractStartDate: null,
          contractEndDate: null,
          regularization: null,
          basicSalary: 0,
          allowance: 0,
          totalSalary: 0,
          hmo: 0,
          hmoTerm: "",
          transpo: 0,
          transpoTerm: "",
          rent: 0,
          rentTerm: "",
          telco: 0,
          telcoTerm: "",
          avatar: null,
        });
      }
    }
  }

  return (
    <>
      <multiStepContext.Provider
        value={{
          currentStep,
          setCurrentStep,
          empInputs,
          setEmpInputs,
          submitData,
        }}
      >
        {children}
      </multiStepContext.Provider>
    </>
  );
};

export const useMultiStep = () => {
  const context = useContext(multiStepContext);
  if (!context) {
    throw new Error("useMultiStep must be used within a MultiStepProvider");
  }
  return context;
};

export default StepContext;
