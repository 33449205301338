import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");
const employee_detailed = JSON.parse(
  localStorage.getItem("account_detail") || "{}"
);

const clientFromStorage: any = localStorage.getItem("employee_detail");
const clientObj = JSON.parse(clientFromStorage);
const client = clientObj?.client?.id;
const clientApi = createApi({
  reducerPath: "client",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  endpoints(builder) {
    return {
      clientAssessment: builder.query({
        query: () => {
          return {
            url: `/api/client/client_api/${employee_detailed.client.id}/client_assessment/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      employeeClientAssessment: builder.mutation({
        query: (data) => {
          console.log(data);
          return {
            url: "api/employee/employee_assessment_list/",
            headers: { Authorization: "token " + token },
            method: "POST",
            body: data,
          };
        },
      }),
      regionList: builder.query({
        query: () => {
          return {
            url: `/api/client/client_api/regions/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      provinceList: builder.query({
        query: () => {
          return {
            url: `/api/client/client_api/province/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      cityList: builder.query({
        query: () => {
          return {
            url: `/api/client/client_api/cities/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      barangayList: builder.query({
        query: () => {
          return {
            url: `/api/client/client_api/barangay/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchManager: builder.query({
        query: () => {
          return {
            url: "api/employee/employee_list/",
            headers: { Authorization: "token " + token },
            params: { name: "Manager", client_id: client },
            method: "GET",
          };
        },
      }),
      fetchSupervisor: builder.query({
        query: () => {
          return {
            url: "api/employee/employee_list/",
            headers: { Authorization: "token " + token },
            params: {
              name: "Supervisor",
              client_id: client,
            },
            method: "GET",
          };
        },
      }),
      fetchLevel: builder.query({
        query: () => {
          return {
            url: `api/client/get_levels/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchJobTitles: builder.query({
        query: () => {
          return {
            url: `api/client/get_job_positions/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useClientAssessmentQuery,
  useEmployeeClientAssessmentMutation,
  useFetchManagerQuery,
  useFetchSupervisorQuery,
  useFetchLevelQuery,
  useFetchJobTitlesQuery,
  useBarangayListQuery,
  useCityListQuery,
  useProvinceListQuery,
  useRegionListQuery,
} = clientApi;
export { clientApi };
