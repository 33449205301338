import React from "react";
import DashboardHeader from "./Dashboard/DashboardHeader";
import DashboardBody from "./Dashboard/DashboardBody";

const Dashboard = () => {
  return (
    <>
      <DashboardHeader />
      <DashboardBody />
    </>
  );
};

export default Dashboard;
